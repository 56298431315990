import React, { useEffect, useState } from 'react';
import {
	Button,
	TextField,
	Card,
	CardContent,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableRow,
	IconButton,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { talentService } from 'services';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/User/userSlice';

const TalentLinks = ({ talentId, links }) => {
	const [newLink, setNewLink] = useState({ description: '', url: '' });
	const [showAddForm, setShowAddForm] = useState(false);
	const [errors, setErrors] = useState({ description: '', url: '' });
	const queryClient = useQueryClient();

	const currentUser = useSelector(selectUser);
	const isCompany = currentUser?.role === 'company';

	useEffect(() => {
		if (links?.length === 0 && !isCompany) {
			setShowAddForm(true);
		}
	}, [links, isCompany]);

	const { mutate: updateLinks, isLoading } = useMutation(
		(updatedTalent) =>
			talentService.updateTalentById(talentId, updatedTalent),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['talentData', talentId]);
				setNewLink({ description: '', url: '' });
				setShowAddForm(false);
				setErrors({ description: '', url: '' });
			},
		},
	);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewLink((prev) => ({ ...prev, [name]: value }));
	};

	const validate = () => {
		let tempErrors = { description: '', url: '' };
		if (!newLink.description)
			tempErrors.description = 'Kuvaus on pakollinen';
		if (!newLink.url) tempErrors.url = 'URL on pakollinen';
		setErrors(tempErrors);
		return Object.values(tempErrors).every((x) => x === '');
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (validate()) {
			const existingLinks = Array.isArray(links) ? links : [];
			const updatedLinks = [...existingLinks, newLink];
			updateLinks({ links: updatedLinks });
		}
	};

	const handleDeleteLink = (index) => {
		const updatedLinks = links.filter((link, idx) => idx !== index);
		updateLinks({ links: updatedLinks });
	};

	return (
		<>
			<Typography
				variant="h6"
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				Liitteet
				{!isCompany && (
					<IconButton
						size="small"
						onClick={() => setShowAddForm(!showAddForm)}
						color="primary"
					>
						{!showAddForm ? <AddIcon /> : <RemoveIcon />}
					</IconButton>
				)}
			</Typography>
			<Card sx={{ p: 2, overflow: 'hidden' }}>
				{showAddForm && (
					<>
						<Typography variant="subtitle1">
							Lisää Linkki
						</Typography>
						<form onSubmit={handleSubmit}>
							<TextField
								label="Kuvaus"
								name="description"
								value={newLink.description}
								onChange={handleInputChange}
								fullWidth
								margin="normal"
								error={!!errors.description}
								helperText={errors.description}
							/>
							<TextField
								label="URL"
								name="url"
								value={newLink.url}
								onChange={handleInputChange}
								fullWidth
								margin="normal"
								required
								error={!!errors.url}
								helperText={errors.url}
							/>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={isLoading}
								sx={{ mt: 2 }}
							>
								{isLoading ? 'Lisätään...' : 'Lisää'}
							</Button>
						</form>
					</>
				)}
				<Table size="small">
					<TableBody>
						{links?.length === 0 ? (
							<TableRow>
								<TableCell>
									<Typography>
										Ei liitteitä. Lisää esimerkiksi
										opintosuoritusote, CV tai
										sertifikaatteja.
									</Typography>
								</TableCell>
							</TableRow>
						) : (
							links?.map((link, index) => (
								<TableRow
									key={index}
									sx={{
										display: 'grid',
										gridTemplateColumns: '1fr auto auto',
										alignItems: 'center',
									}}
								>
									<TableCell
										component="th"
										scope="row"
										sx={{ flex: 1 }}
									>
										{link.description || 'Ei kuvausta'}
									</TableCell>
									<TableCell sx={{ borderBottom: 'none' }}>
										<a
											href={link.url}
											target="_blank"
											rel="noopener noreferrer"
										>
											<Button size="small">Avaa</Button>
										</a>
									</TableCell>
									{!isCompany && showAddForm && (
										<TableCell
											sx={{ borderBottom: 'none' }}
										>
											<IconButton
												onClick={() =>
													handleDeleteLink(index)
												}
												aria-label="delete"
											>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									)}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</Card>
		</>
	);
};

export default TalentLinks;
