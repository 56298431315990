import React from 'react';
import { Grid, Paper, Typography, Box, useTheme } from '@mui/material';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	PieChart,
	Pie,
	Cell,
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
} from 'recharts';
import GlobeComponent from './GlobeComponent';

const jobOpeningsData = [
	{ name: 'Avoinna', value: 45, fill: '#343E3D' },
	{ name: 'Täytetty', value: 55, fill: '#E8C1C5' },
];

const COLORS = ['#7C9EB2', '#D499B9', '#E8C1C5', '#343E3D'];

const Dashboard = ({ data, talents }) => {
	const theme = useTheme();

	const dashboardItems = [
		{
			label: 'Avoinna olevat rekrytoinnit',
			value: data?.recruitCount,
			color: theme.palette.primary.main,
		},
		{
			label: 'Talenttien määrä',
			value: data?.talentCount,
			color: theme.palette.primary.main,
		},
		{
			label: 'Yritysten määrä',
			value: data?.companyCount,
			color: theme.palette.primary.main,
		},
		{
			label: 'Hakemuksia yhteensä',
			value: data?.applicationCount,
			color: theme.palette.primary.main,
		},
	];

	const globeData = talents?.data?.results
		?.filter(
			(talent) => talent.coordinates && talent.coordinates.length === 2,
		)
		?.map((talent) => ({
			lat: talent.coordinates[1], // lat is the second element
			lng: talent.coordinates[0], // lng is the first element
			size: 0.1,
			color: 'white',
			city: talent.city,
		}));

	const NPSData = [{ name: 'NPS Keskiarvo', value: 75 }];

	return (
		<Box>
			<Typography variant="h4" gutterBottom>
				Dashboard
			</Typography>

			<Grid container spacing={3}>
				{/* Maapallokomponentti */}
				<Grid item xs={12} md={8}>
					<Paper
						elevation={3}
						sx={{
							p: 2,
							backgroundColor: '#1c1e29',
							height: '445px',
						}}
					>
						<Typography color="white" variant="h6">
							Talenttien Sijainnit
						</Typography>
						<Box
							sx={{
								width: '100%',
								height: '100%',
								maxHeight: '400px',
							}}
						>
							<GlobeComponent globeData={globeData} />
						</Box>
					</Paper>
				</Grid>

				{/* Numeerinen data */}
				<Grid item xs={12} md={4}>
					<Grid container spacing={3}>
						{dashboardItems.map((item, index) => (
							<Grid item xs={12} key={index}>
								<Paper elevation={3} sx={{ p: 2 }}>
									<Typography
										color="textSecondary"
										gutterBottom
									>
										{item.label}
									</Typography>
									<Typography variant="h5" color={item.color}>
										{item.value}
									</Typography>
								</Paper>
							</Grid>
						))}
					</Grid>
				</Grid>

				{/* Rekrytointivaiheiden jakautuma */}
				<Grid item xs={12} md={6}>
					<Paper elevation={3} sx={{ p: 2, height: '100%' }}>
						<Typography variant="h6">
							Rekrytointivaiheiden jakauma
						</Typography>
						<ResponsiveContainer width="100%" height={300}>
							<BarChart data={data?.stageAnalytics}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Legend />
								<Bar
									dataKey="value"
									fill={theme.palette.primary.main}
								/>
							</BarChart>
						</ResponsiveContainer>
					</Paper>
				</Grid>

				{/* Talenttien osaamisalueiden jakauma */}
				<Grid item xs={12} md={6}>
					<Paper elevation={3} sx={{ p: 2, height: '100%' }}>
						<Typography variant="h6">
							Talenttien osaamisalueiden jakauma
						</Typography>
						<ResponsiveContainer width="100%" height={300}>
							<RadarChart
								outerRadius={90}
								data={data?.categoryCounts}
							>
								<PolarGrid />
								<PolarAngleAxis dataKey="subject" />
								<PolarRadiusAxis angle={30} />
								<Radar
									name="Talents"
									dataKey="A"
									stroke={theme.palette.primary.main}
									fill={theme.palette.secondary.light}
									fillOpacity={0.6}
								/>
								<Legend />
							</RadarChart>
						</ResponsiveContainer>
					</Paper>
				</Grid>
				{/* 
				
				<Grid item xs={12} md={6}>
					<Paper elevation={3} sx={{ p: 2, height: '100%' }}>
						<Typography variant="h6">Työpaikkojen Suhde</Typography>
						<ResponsiveContainer width="100%" height={300}>
							<PieChart>
								<Pie
									data={jobOpeningsData}
									dataKey="value"
									nameKey="name"
									cx="50%"
									cy="50%"
									outerRadius={80}
									label
								>
									{jobOpeningsData.map((entry, index) => (
										<Cell
											key={`cell-${index}`}
											fill={entry.fill}
										/>
									))}
								</Pie>
								<Tooltip />
								<Legend />
							</PieChart>
						</ResponsiveContainer>
					</Paper>
				</Grid>

				
				<Grid item xs={12} md={6}>
					<Paper elevation={3} sx={{ p: 2, height: '100%' }}>
						<Typography variant="h6" sx={{ mb: 2 }}>
							NPS
						</Typography>
						<ResponsiveContainer width="100%" height={250}>
							<PieChart>
								<Pie
									data={NPSData}
									dataKey="value"
									nameKey="name"
									cx="50%"
									cy="50%"
									outerRadius={80}
									fill={theme.palette.primary.main}
									label
								>
									{NPSData.map((entry, index) => (
										<Cell
											key={`cell-${index}`}
											fill={COLORS[index % COLORS.length]}
										/>
									))}
								</Pie>
								<Legend />
							</PieChart>
						</ResponsiveContainer>
					</Paper>
				</Grid> */}
			</Grid>
		</Box>
	);
};

export default Dashboard;
