import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	user: null,
	status: 'idle',
	error: null,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		clearUser: (state) => {
			state.user = null;
		},
		logoutUser: () => {
			return { user: null };
		},
	},
});

export const { setUser, clearUser, logoutUser } = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectIsTalent = (state) => state.user.user?.role === 'talent';
export const selectIsAdmin = (state) => state.user.user?.role === 'admin';

export default userSlice.reducer;
