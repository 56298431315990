import React, { useState, useEffect, useCallback } from 'react';
import {
	Box,
	Typography,
	InputBase,
	Button,
	Grid,
	Container,
	MenuItem,
	Select,
	Toolbar,
	ToggleButton,
	ToggleButtonGroup,
	CircularProgress,
	useTheme,
	TextField,
	Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TalentCard from 'features/Talents/components/TalentCard';
import logo from '../../../logo.png';
import { TypeAnimation } from 'react-type-animation';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import { talentService } from '../../../services';
import { openaiService } from '../../../services/openaiService';
import { pink } from '../getLPTheme';
import { useNavigate } from 'react-router-dom';

const CompanyHero = () => {
	const [searchQuery, setSearchQuery] = useState('');
	const [results, setResults] = useState([]);
	const [projectType, setProjectType] = useState('resurssivuokra');
	const [location, setLocation] = useState('Remote');
	const [loading, setLoading] = useState(false);
	const [isTyping, setIsTyping] = useState(false);
	const [feedback, setFeedback] = useState('');

	const { data, error, isLoading, refetch } = useQuery(
		['publicTalentsData', searchQuery],
		() => talentService.hybridSearch(searchQuery),
		{
			enabled: false,
			onSuccess: handleSuccess,
		},
	);

	const theme = useTheme();

	const navigate = useNavigate();

	const handleTalentChatClick = () => {
		navigate('/talent-chat');
	};

	function handleSuccess(data) {
		if (data) {
			const topResults = data.slice(0, 3);

			setResults(topResults);
		} else {
			setResults([]);
		}
		setLoading(false);
		setIsTyping(false);
	}

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setIsTyping(true);
		setFeedback('');
	};

	const MIN_QUERY_LENGTH = 10;

	const debouncedSearch = useCallback(
		debounce(async () => {
			if (searchQuery.trim().length >= MIN_QUERY_LENGTH) {
				const { isAppropriate, feedback } =
					await openaiService.analyzeQuery(searchQuery);
				if (isAppropriate) {
					setFeedback(feedback);
					refetch();
				} else {
					setFeedback(feedback);
					setLoading(false);
					setIsTyping(false);
				}
			}
		}, 500),
		[refetch, searchQuery],
	);

	useEffect(() => {
		if (searchQuery.trim().length >= MIN_QUERY_LENGTH) {
			debouncedSearch();
		} else {
			setResults([]);
			setLoading(false);
			setIsTyping(false);
		}
		return () => {
			debouncedSearch.cancel();
		};
	}, [searchQuery, debouncedSearch]);

	return (
		<HeroSection>
			<Container maxWidth="lg">
				<Typography variant="h2" gutterBottom color="text.primary">
					Löydä tulevaisuuden huippuosaajat!
				</Typography>
				<Typography
					sx={{
						color: 'text.secondary',
					}}
				>
					Lenz yhdistää sinut alan parhaiden nuorten ammattilaisten
					kanssa. Kerro tarpeesi ja löydä täydellinen match!
				</Typography>
				<SearchToolbar>
					<Select
						value={location}
						variant="standard"
						onChange={(e) => setLocation(e.target.value)}
						displayEmpty
						startAdornment={<LocationOnIcon sx={{ mr: 1 }} />}
						sx={{
							minWidth: 150,
							mr: 2,
							backgroundColor: 'transparent',
							border: 'none',
							'& .MuiOutlinedInput-notchedOutline': {
								border: 'none',
							},
							'&:before': {
								borderBottom: 'none',
							},
							'&:after': {
								borderBottom: 'none',
							},
						}}
					>
						<MenuItem value="Remote">Etätyö</MenuItem>
						<MenuItem value="Helsinki">Lähityö</MenuItem>
						<MenuItem value="Tampere">Hybridi</MenuItem>
					</Select>
					<InputBase
						fullWidth
						placeholder="Kirjoita resurssitarpeesi..."
						value={searchQuery}
						onChange={handleSearchChange}
						multiline={true}
						rows={1}
						sx={{
							mr: 2,
							borderRadius: '50px',
							backgroundColor: 'transparent',
							padding: '15px 20px',
							[theme.breakpoints.down('sm')]: {
								rows: 3, // Asetetaan rivien määrä mobiilissa
							},
						}}
					/>
					<ToggleButtonGroup
						value={projectType}
						exclusive
						onChange={(e, newType) =>
							newType !== null && setProjectType(newType)
						}
						aria-label="project type"
						sx={{
							mr: 2,
							padding: '4px',
							borderRadius: '50px',
							'& .MuiToggleButton-root': {
								borderRadius: '50px',
								border: 'none',
								backgroundColor: 'transparent',

								'&.Mui-selected': {
									backgroundColor:
										theme.palette.primary.light,
								},
							},
						}}
					>
						<ToggleButton
							value="resurssivuokra"
							aria-label="resurssivuokra"
						>
							Resurssivuokra
						</ToggleButton>
						<ToggleButton value="projekti" aria-label="projekti">
							Projekti
						</ToggleButton>
					</ToggleButtonGroup>
				</SearchToolbar>

				{loading ||
				isTyping ||
				(searchQuery.trim().length > 0 &&
					searchQuery.trim().length < MIN_QUERY_LENGTH) ? (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							mt: 12,
						}}
					>
						<Box
							component="img"
							src={logo}
							alt="Lenz logo"
							sx={{
								width: '80px',
								height: '93px',
								animation: 'spin 2s linear infinite',
								'@keyframes spin': {
									'0%': {
										transform: 'rotate(0deg)',
									},
									'100%': {
										transform: 'rotate(360deg)',
									},
								},
							}}
						/>
						<Typography
							variant="h6"
							sx={{
								mt: 2,
								fontStyle: 'italic',
								color: 'text.secondary',
							}}
						>
							{searchQuery.trim().length > 0 &&
							searchQuery.trim().length < MIN_QUERY_LENGTH
								? `Hakusanan on oltava vähintään ${MIN_QUERY_LENGTH} merkkiä pitkä.`
								: 'Etsitään juuri sinulle sopivia ehdokkaita...'}
						</Typography>
					</Box>
				) : (
					<Box sx={{ mt: 4 }}>
						{results.length > 0 ? (
							<Grid container spacing={2}>
								{results.map(
									({ talent, vs_score, fts_score }) => (
										<TalentCard
											key={talent.id}
											talent={talent}
											vs_score={vs_score}
											fts_score={fts_score}
										/>
									),
								)}
							</Grid>
						) : (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									mt: 12,
								}}
							>
								<Box
									component="img"
									src={logo}
									alt="Lenz logo"
									sx={{
										width: '80px',
										height: '93px',
									}}
								/>
							</Box>
						)}
					</Box>
				)}
				{feedback && (
					<Typography variant="body2" sx={{ mt: 2 }}>
						{feedback}
					</Typography>
				)}

				{/* Lisätään uusi osio TalentChat-linkille
				<Box sx={{ mt: 4, textAlign: 'center' }}>
					<Typography variant="h5" gutterBottom>
						Kokeile uutta TalentChat-ominaisuuttamme!
					</Typography>
					<Typography variant="body1" paragraph>
						Haluatko rakentaa huipputiimejä tai saada edistynyttä
						sparrausta? Tutustu beta-versioon uudesta
						TalentChat-tuotteestamme!
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={handleTalentChatClick}
					>
						Siirry TalentChattiin
					</Button>
				</Box> */}
			</Container>
		</HeroSection>
	);
};

const HeroSection = styled(Box)(({ theme }) => ({
	width: '100%',
	minHeight: '700px',
	backgroundRepeat: 'no-repeat',
	backgroundImage: `radial-gradient(ellipse 80% 50% at 50% -20%, white, transparent)`,
	...theme.applyStyles('dark', {
		backgroundImage: `radial-gradient(ellipse 80% 50% at 50% -20%, white transparent)`,
	}),

	paddingTop: theme.spacing(17),
	textAlign: 'center',
	[theme.breakpoints.down('sm')]: {
		height: 'auto',
		paddingTop: theme.spacing(10, 0),
	},
}));

const SearchToolbar = styled(Toolbar)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: '40px',
	borderRadius: '50px',
	backdropFilter: 'blur(10px)',
	padding: '10px !important',
	transition: 'all 0.3s ease-in-out',
	boxSizing: 'border-box',
	boxShadow: `0 0 24px 12px hsla(210, 100%, 25%, 0.2)`,
	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		padding: '20px',
		borderRadius: '20px', // Pienennetään border-radius mobiilissa
		'& > *': {
			marginBottom: theme.spacing(2),
			width: '100%',
			marginRight: 0, // Poistetaan oikea marginaali mobiilissa
		},
		'& .MuiToggleButtonGroup-root': {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
		},
		'& .MuiButtonBase-root': {
			flex: 1,
		},
	},
	[theme.breakpoints.up('md')]: {
		flexDirection: 'row',
		borderRadius: '50px', // Palautetaan border-radius työpöytäversiossa
		'& > *': {
			marginRight: theme.spacing(2),
		},
		'& > *:last-child': {
			marginRight: 0,
		},
		'& .MuiButtonBase-root.MuiButton-root': {
			display: 'none', // Piilotetaan vain "Hae" -nappi työpöytäversiossa
		},
		'& .MuiToggleButtonGroup-root': {
			width: 'auto', // Palautetaan toggle-painikkeiden leveys työpöytäversiossa
			display: 'flex',
			justifyContent: 'flex-start',
		},
	},
}));

const ChatContainer = styled(Paper)(({ theme }) => ({
	marginTop: theme.spacing(4),
	padding: theme.spacing(2),
	maxHeight: '400px',
	display: 'flex',
	flexDirection: 'column',
}));

const ChatMessages = styled(Box)({
	flexGrow: 1,
	overflowY: 'auto',
	marginBottom: '16px',
});

const ChatMessage = styled(Box)(({ theme, role }) => ({
	padding: theme.spacing(1),
	marginBottom: theme.spacing(1),
	borderRadius: '8px',
	maxWidth: '70%',
	alignSelf: role === 'user' ? 'flex-end' : 'flex-start',
	backgroundColor:
		role === 'user' ? theme.palette.primary.light : theme.palette.grey[200],
}));

const ChatInput = styled(Box)({
	display: 'flex',
	gap: '8px',
});

export default CompanyHero;
