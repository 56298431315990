import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Typography, Box, Chip, Paper, Grid, Button } from '@mui/material';
import { talentService } from 'services';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/User/userSlice';
import FormComponent from '../../../components/FormComponent';
import ModalComponent from '../../../components/ModalComponent';
import { updateTalentDto } from '../../../services/talentDTO';

const CompleteProfileForm = ({ open, onClose, initialData }) => {
	const currentUser = useSelector(selectUser);
	const queryClient = useQueryClient();
	const [formData, setFormData] = useState(initialData);

	const defaultValues = {
		city: '',
		university: '',
		stage: '',
		major: '',
		minor: '',
		phone: '',
		motto: '',
		category: [],
	};

	useEffect(() => {
		setFormData(initialData);
	}, [initialData]);

	const mutation = useMutation(
		(data) => talentService.updateTalentById(currentUser.profileId, data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					'talentData',
					currentUser.profileId,
				]);
				onClose();
			},
		},
	);

	const handleCategoryToggle = (category) => {
		setFormData((prevData) => {
			const categoryExists = prevData.category.includes(category);
			return {
				...prevData,
				category: categoryExists
					? prevData.category.filter((c) => c !== category)
					: [...prevData.category, category],
			};
		});
	};

	const onSubmit = (data) => {
		const combinedData = { ...formData, ...data };
		const dto = updateTalentDto(combinedData);
		mutation.mutate(dto);
	};

	const fields = [
		{
			name: 'city',
			label: 'Kaupunki',
			type: 'text',
			validation: { required: 'Tämä kenttä on pakollinen' },
		},
		{
			name: 'university',
			label: 'Koulu',
			type: 'text',
			validation: { required: 'Tämä kenttä on pakollinen' },
		},
		{
			name: 'stage',
			label: 'Koulutustaso',
			type: 'select',
			options: [
				{ value: 'Bachelor', label: 'Kandidaatti' },
				{ value: 'Master', label: 'Maisteri' },
				{ value: 'amk', label: 'Alempi AMK' },
				{ value: 'yamk', label: 'Ylempi AMK' },
				{ value: 'valmistunut', label: 'Olen valmistunut' },
			],
			validation: { required: 'Tämä kenttä on pakollinen' },
		},
		{
			name: 'major',
			label: 'Pääaine',
			type: 'text',
			validation: { required: 'Tämä kenttä on pakollinen' },
		},
		{ name: 'minor', label: 'Sivuaineet', type: 'text' },
		{ name: 'phone', label: 'Puhelinnumero', type: 'text' },
		{
			name: 'motto',
			label: 'Mikä on mottosi?',
			type: 'text',
			multiline: true,
			rows: 2,
		},
		{
			name: 'category',
			label: 'Osa-alueet',
			type: 'tags',
			options: ['Tech', 'Business', 'Design'],
			onChange: handleCategoryToggle,
		},
	];

	const customStyles = {
		formContainer: {
			backgroundColor: '#f5f5f5',
		},
		formTitle: {
			color: '#3f51b5',
		},
	};

	return (
		<ModalComponent
			open={open}
			onClose={onClose}
			title="Täydennä Perustiedot"
			actions={
				<>
					<Button onClick={onClose}>Peruuta</Button>
					<Button
						form="complete-profile-form"
						type="submit"
						variant="contained"
					>
						Täydennä profiili
					</Button>
				</>
			}
		>
			<Paper elevation={3} style={customStyles.formContainer}>
				<Box mt={3}>
					<FormComponent
						id="complete-profile-form"
						fields={fields}
						onSubmit={onSubmit}
						defaultValues={formData}
						customStyles={customStyles}
					/>
				</Box>
			</Paper>
		</ModalComponent>
	);
};

export default CompleteProfileForm;
