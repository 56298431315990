import {
	Typography,
	Paper,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Rating,
	Button,
	TextField,
	IconButton,
	Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/User/userSlice';
import { styled } from '@mui/system';

import { talentService } from '../../../services';

const Skills = ({ skills, talentId, color }) => {
	const queryClient = useQueryClient();
	const [newSkill, setNewSkill] = useState('');
	const [newRating, setNewRating] = useState(0);
	const [editMode, setEditMode] = useState(false);

	const currentUser = useSelector(selectUser);
	const isCompany = currentUser?.role === 'company';

	useEffect(() => {
		if (skills?.length === 0 && !isCompany) {
			setEditMode(true);
		}
	}, [skills, isCompany]);

	const updateSkillMutation = useMutation(
		(updatedTalent) =>
			talentService.updateTalentById(talentId, updatedTalent),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['talentData', talentId]);
			},
		},
	);

	const handleRatingChange = (index, newValue) => {
		const updatedSkills = skills.map((skill, idx) =>
			idx === index ? { ...skill, rating: newValue } : skill,
		);
		updateSkillMutation.mutate({ skills: updatedSkills });
	};

	const handleAddSkill = () => {
		const updatedSkills = [
			...skills,
			{ skill: newSkill, rating: newRating },
		];
		updateSkillMutation.mutate({ skills: updatedSkills });
		setNewSkill('');
		setNewRating(0);
	};

	const handleDeleteSkill = (index) => {
		const updatedSkills = skills.filter((skill, idx) => idx !== index);
		updateSkillMutation.mutate({ skills: updatedSkills });
	};

	const CustomRating = styled(Rating)(({ theme }) => ({
		'& .MuiRating-iconFilled': {
			color: color,
		},
		'& .MuiRating-iconHover': {
			color: color,
		},
	}));

	return (
		<>
			<Typography
				variant="h6"
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				Taidot
				{!isCompany && (
					<IconButton
						size="small"
						onClick={() => setEditMode(!editMode)}
					>
						{editMode ? <RemoveIcon /> : <AddIcon />}
					</IconButton>
				)}
			</Typography>
			<Paper>
				<Table size="small">
					<TableBody>
						{skills?.length === 0 ? (
							<Box sx={{ p: 2 }}>
								<Typography>Ei taitoja.</Typography>
							</Box>
						) : (
							skills?.map((skill, index) => (
								<TableRow key={index}>
									<TableCell>{skill.skill}</TableCell>
									<TableCell>
										<CustomRating
											name={`skill-rating-${index}`}
											value={skill.rating}
											onChange={(event, newValue) =>
												handleRatingChange(
													index,
													newValue,
												)
											}
											readOnly={isCompany}
										/>
									</TableCell>
									{!isCompany && editMode && (
										<TableCell>
											<IconButton
												onClick={() =>
													handleDeleteSkill(index)
												}
												aria-label="delete"
											>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									)}
								</TableRow>
							))
						)}

						{!isCompany && editMode && (
							<TableRow>
								<TableCell>
									<TextField
										label="Uusi taito"
										size="small"
										value={newSkill}
										onChange={(e) =>
											setNewSkill(e.target.value)
										}
									/>
								</TableCell>
								<TableCell>
									<CustomRating
										value={newRating}
										onChange={(event, newValue) =>
											setNewRating(newValue)
										}
									/>
								</TableCell>
								<TableCell>
									<Button onClick={handleAddSkill}>
										Lisää
									</Button>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</Paper>
		</>
	);
};

export default Skills;
