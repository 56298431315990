import axios from './axios';

const getUsers = async () => {
	const response = await axios.get(`/users?limit=30`);
	return response;
};

const addUser = async (newUser) => {
	const response = await axios.post(`/users`, newUser);
	return response.data;
};

const updateUserById = async (id, newUser) => {
	const response = await axios.patch(`/users/${id}`, newUser);
	return response.data;
};

const deleteUser = async (id) => {
	const response = await axios.delete(`/users/${id}`);
	return response.data;
};

export { getUsers, addUser, updateUserById, deleteUser };
