import React from 'react';
import { Menu, MenuItem, Divider } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLogout } from 'hooks/useLogout';

const UserMenu = ({ anchorEl, handleMenuClose }) => {
	const logout = useLogout();

	const handleLogoutClick = () => {
		logout();
		handleMenuClose(); // Sulje valikko uloskirjautumisen jälkeen
	};

	return (
		<Menu
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={handleMenuClose}
			sx={{ mt: '10px' }}
		>
			{/* Lisää muut valikkokohdat täällä */}
			<Divider />
			<MenuItem onClick={handleLogoutClick}>
				<LogoutIcon sx={{ marginRight: 1 }} />
				Kirjaudu ulos
			</MenuItem>
		</Menu>
	);
};

export default UserMenu;
