import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
	TextField,
	Slider,
	Checkbox,
	FormControlLabel,
	Grid,
	Typography,
	Box,
	Chip,
	MenuItem,
} from '@mui/material';
import Colorful from '@uiw/react-color-colorful';

const FormComponent = ({
	id,
	fields,
	onSubmit,
	defaultValues,
	customStyles,
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues,
	});

	React.useEffect(() => {
		reset(defaultValues);
	}, [defaultValues, reset]);

	return (
		<form
			id={id}
			onSubmit={handleSubmit(onSubmit)}
			style={customStyles?.formContainer}
		>
			<Grid container spacing={3}>
				{fields.map((field, index) => (
					<Grid
						item
						xs={12}
						key={index}
						style={customStyles?.formField}
					>
						{field.type === 'text' ? (
							<Controller
								name={field.name}
								control={control}
								rules={field.validation}
								render={({ field: controllerField }) => (
									<TextField
										{...controllerField}
										label={field.label}
										multiline={field.multiline}
										rows={field.rows}
										fullWidth
										variant="outlined"
										error={!!errors[field.name]}
										helperText={errors[field.name]?.message}
									/>
								)}
							/>
						) : field.type === 'slider' ? (
							<Controller
								name={field.name}
								control={control}
								rules={field.validation}
								render={({ field: controllerField }) => (
									<>
										<Typography gutterBottom>
											{field.label}
										</Typography>
										<Box display="flex" alignItems="center">
											<Typography
												variant="body2"
												color="textSecondary"
											>
												{field.minLabel}
											</Typography>
											<Slider
												{...controllerField}
												valueLabelDisplay="auto"
												min={field.min}
												max={field.max}
												step={field.step}
												style={{ margin: '0 10px' }}
											/>
											<Typography
												variant="body2"
												color="textSecondary"
											>
												{field.maxLabel}
											</Typography>
										</Box>
									</>
								)}
							/>
						) : field.type === 'checkbox' ? (
							<Controller
								name={field.name}
								control={control}
								rules={field.validation}
								render={({ field: controllerField }) => (
									<FormControlLabel
										control={
											<Checkbox
												{...controllerField}
												checked={controllerField.value}
											/>
										}
										label={field.label}
									/>
								)}
							/>
						) : field.type === 'color' ? (
							<>
								<Typography variant="body1">
									{field.label}
								</Typography>
								<Controller
									name={field.name}
									control={control}
									render={({ field: controllerField }) => (
										<Colorful
											color={controllerField.value}
											onChange={controllerField.onChange}
										/>
									)}
								/>
							</>
						) : field.type === 'tags' ? (
							<>
								<Typography variant="body1">
									{field.label}
								</Typography>
								<Box
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
										gap: 1,
									}}
								>
									{field.options.map((option) => (
										<Chip
											key={option}
											label={option}
											clickable
											color={
												defaultValues[
													field.name
												].includes(option)
													? 'primary'
													: 'default'
											}
											onClick={() =>
												field.onChange(option)
											}
										/>
									))}
								</Box>
							</>
						) : null}
					</Grid>
				))}
			</Grid>
		</form>
	);
};

export default FormComponent;
