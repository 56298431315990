import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Slider, useTheme } from '@mui/material';

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));

const DeepTestResults = ({ deepTest, questions }) => {
	const [expanded, setExpanded] = useState({});
	const theme = useTheme();

	const handleExpandClick = (sectionId) => {
		setExpanded((prev) => ({
			...prev,
			[sectionId]: !prev[sectionId],
		}));
	};

	if (!deepTest || deepTest.length === 0) {
		return (
			<Typography variant="body1" sx={{ color: 'text.secondary' }}>
				Testiä ei ole täytetty.
			</Typography>
		);
	}

	const getQuestionDetails = (questionText, sectionQuestions) => {
		return sectionQuestions.find((q) => q.label === questionText) || {};
	};

	return (
		<Grid item xs={12}>
			<Typography variant="h6">Syventävä testi</Typography>
			{deepTest.map((section) => {
				const sectionDetails = questions.find(
					(q) => q.title === section.section,
				);
				const isSectionExpanded = expanded[section._id];

				return (
					<Box key={section._id} sx={{ mb: 3 }}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								mb: 1,
								backgroundColor: theme.palette.primary.main,
								color: theme.palette.primary.contrastText,
								p: 1,
								borderRadius: 1,
							}}
						>
							<Typography
								variant="subtitle2"
								sx={{ fontWeight: 'bold' }}
							>
								{section.section}
							</Typography>
							<ExpandMore
								expand={expanded[section._id]}
								onClick={() => handleExpandClick(section._id)}
								aria-expanded={expanded[section._id]}
								aria-label="show more"
							>
								<ExpandMoreIcon
									sx={{
										color: theme.palette.primary
											.contrastText,
									}}
								/>
							</ExpandMore>
						</Box>
						<Collapse
							in={expanded[section._id]}
							timeout="auto"
							unmountOnExit
						>
							{section.responses.map((response) => {
								const questionDetails = getQuestionDetails(
									response.question,
									sectionDetails.questions,
								);
								return (
									<Box key={response._id} sx={{ mb: 2 }}>
										<Typography
											variant="subtitle2"
											sx={{ fontWeight: 'bold' }}
										>
											{response.question}
										</Typography>
										{questionDetails.type === 'opinion' ? (
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Typography
													variant="body2"
													sx={{ mr: 2 }}
												>
													{questionDetails.minLabel}
												</Typography>
												<Slider
													value={response.answer}
													min={questionDetails.min}
													max={questionDetails.max}
													step={questionDetails.step}
													marks
													valueLabelDisplay="auto"
													disabled
												/>
												<Typography
													variant="body2"
													sx={{ ml: 2 }}
												>
													{questionDetails.maxLabel}
												</Typography>
											</Box>
										) : (
											<Typography variant="body2">
												{response.answer}
											</Typography>
										)}
									</Box>
								);
							})}
						</Collapse>
					</Box>
				);
			})}
		</Grid>
	);
};

export default DeepTestResults;
