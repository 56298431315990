import React from 'react';
import {
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Chip,
	Box,
	Divider,
} from '@mui/material';

const RecruitCard = ({ recruit, onClick }) => {
	const formattedEndDate = new Date(recruit.endDate).toLocaleDateString(
		'fi-FI',
		{
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		},
	);

	return (
		<Card
			sx={{
				marginRight: 2,
				position: 'relative',
				transition: 'box-shadow 0.3s',
				'&:hover': { boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)' },
				cursor: 'pointer',
			}}
			onClick={onClick}
		>
			<CardActionArea>
				<CardContent
					sx={{
						'&:hover': { '.readMore': { color: 'primary.main' } },
					}}
				>
					<Chip
						label={recruit.industry}
						color="primary"
						size="small"
						sx={{ mb: 1 }}
					/>
					<Typography variant="h6" component="div" gutterBottom>
						{recruit.name}
					</Typography>
					<Divider />
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							mb: 1,
						}}
					>
						<Typography
							variant="body1"
							color="text.secondary"
							gutterBottom
						>
							{recruit.company?.name}
						</Typography>
						<Typography
							variant="body2"
							className="readMore"
							sx={{
								fontWeight: 'bold',
								cursor: 'pointer',
								transition: 'color 0.3s',
							}}
						>
							Lue lisää
						</Typography>
					</Box>
					<Typography
						variant="caption"
						color="text.secondary"
						align="left"
					>
						Hakuaika päättyy:{' '}
						<b>
							{recruit.endDate
								? formattedEndDate
								: 'Heti kun tekijä löytyy'}
						</b>
					</Typography>
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{ textAlign: 'left' }}
					>
						{recruit.description.slice(0, 150)}
						{recruit.description.length > 150 ? '...' : ''}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default RecruitCard;
