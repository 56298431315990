import React from 'react';
import { Typography, Box, Paper } from '@mui/material';

const AcademyPage = () => {
	return (
		<Box>
			<Typography
				variant="h4"
				gutterBottom
				sx={{ color: '#1c1e29', fontWeight: 'bold' }}
			>
				Tietopankki – Tulossa pian!
			</Typography>
			<Paper
				elevation={3}
				sx={{
					p: { xs: 3, sm: 5 },
					textAlign: 'center',
				}}
			>
				<Typography variant="body1" paragraph>
					Lenz Platformin tietopankki on kohta täällä! Se tarjoaa
					opiskelijoille ja asiantuntijoille ainutlaatuisen
					mahdollisuuden kehittää työelämätaitojaan ja syventää
					osaamistaan omalla alallaan. Tietopankista löydät
					työelämälähtöistä koulutusmateriaalia, kursseja ja
					resursseja, jotka on suunniteltu auttamaan sinua kasvamaan
					oman alasi huippuosaajaksi.
				</Typography>
				<Typography variant="body1" paragraph>
					Opiskelijoille tietopankki tarjoaa käytännön tietoa ja
					konkreettisia työkaluja, jotka täydentävät akateemisia
					opintoja ja auttavat menestymään työelämässä. Se on
					mahdollisuus syventää osaamistasi ja saada juuri niitä
					taitoja, joita työelämässä tarvitaan.
				</Typography>

				<Typography variant="body1" paragraph>
					Pysy kuulolla – tietopankki on pian käytettävissä ja
					mahdollistaa myös opiskelijoiden oman tiedon lisäämisen
					tietopankkiin!
				</Typography>
			</Paper>
		</Box>
	);
};

export default AcademyPage;
