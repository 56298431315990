import React from 'react';
import { Card, CardHeader, Typography, IconButton } from '@mui/material';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

const ApplicantCard = ({ applicant, provided, openApplicationModal }) => (
	<Card
		ref={provided.innerRef}
		{...provided.draggableProps}
		{...provided.dragHandleProps}
		sx={{
			maxWidth: 345,
			backgroundColor: '#1c1e29',
			border: 1,
			borderColor: '#ffea94',
			color: 'white',
			marginTop: '10px',
			display: 'flex',
			flexDirection: 'column',
		}}
	>
		<CardHeader
			title={
				<Typography
					variant="h5"
					sx={{ color: 'white', fontWeight: 'bold' }}
				>
					{applicant?.talent?.user?.name || applicant?.talent?.name}
				</Typography>
			}
			subheader={
				<>
					<Typography variant="body2" sx={{ color: 'white' }}>
						{applicant?.talent?.major}
					</Typography>
					<Typography variant="body2" sx={{ color: 'white' }}>
						TalentPool rank: {applicant?.talent?.talentPoolRank}
					</Typography>
				</>
			}
			action={
				// Add IconButton here that when clicked, triggers the modal dialog
				<IconButton
					onClick={() => openApplicationModal(applicant.talent.id)}
					aria-label="info"
				>
					<ReadMoreIcon sx={{ color: 'white' }} />
				</IconButton>
			}
			sx={{
				alignItems: 'flex-start', // Asettaa sisällön vasempaan reunaan
				'.MuiCardHeader-content': { overflow: 'hidden' }, // Estää sisällön ylityksen
			}}
		/>
	</Card>
);

export default ApplicantCard;
