import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const EmptyState = ({ isAdmin, onAddClick }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				textAlign: 'center',
				padding: 3,
				bgcolor: 'background.paper',
				borderRadius: 1,
			}}
		>
			<Typography variant="h6" color="textSecondary" gutterBottom>
				{isAdmin
					? 'Ei avoimia rekrytointeja tällä hetkellä.'
					: 'Ei avoimia rekrytointeja.'}
			</Typography>
			{isAdmin ? (
				<>
					<Typography
						variant="body1"
						color="textSecondary"
						gutterBottom
					>
						Voit lisätä uuden rekrytoinnin klikkaamalla alla olevaa
						painiketta.
					</Typography>
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddCircleOutlineIcon />}
						sx={{ mt: 2 }}
						onClick={onAddClick}
					>
						Lisää uusi rekrytointi
					</Button>
				</>
			) : (
				<>
					<Typography
						variant="body1"
						color="textSecondary"
						gutterBottom
					>
						Huomioithan, että suurimmassa osassa tehtäviä ei avata
						erillistä rekrytointia, vaan valitsemme sopivat talentit
						suoraan Talentpoolistamme.
					</Typography>
					<Typography
						variant="body1"
						color="textSecondary"
						gutterBottom
					>
						Varmista, että profiilisi on ajan tasalla ja että
						esittelytekstisi kertoo kattavasti osaamisestasi ja
						motivaatiostasi.
					</Typography>
					<Button
						component={RouterLink}
						to="/app"
						variant="contained"
						color="primary"
						sx={{ mt: 2 }}
					>
						Viimeistele profiilisi
					</Button>
				</>
			)}
		</Box>
	);
};

export default EmptyState;
