import { configureStore } from '@reduxjs/toolkit';
import recruitReducer from './features/Recruit/recruitSlice';
import talentReducer from './features/Talent/talentSlice';
import userReducer from './features/User/userSlice';
import notificationReducer from 'features/Notification/notificationSlice';

export default configureStore({
	reducer: {
		recruits: recruitReducer,
		talents: talentReducer,
		user: userReducer,
		notification: notificationReducer,
	},
});
