import axios from 'axios';
import { setUser } from '../features/User/userSlice';
import { setToken } from './axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const URL = baseUrl + '/v1/auth';

const login = async (credentials, dispatch) => {
	try {
		const response = await axios.post(`${URL}/login`, credentials);
		const { user, tokens } = response.data;
		window.localStorage.setItem(
			'loggedUser',
			JSON.stringify(response.data),
		);
		setToken(tokens.access.token);
		dispatch(setUser(user));
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 401) {
			throw new Error('Incorrect email or password');
		}
		throw new Error(
			error.response ? error.response.data.message : 'Network Error',
		);
	}
};

const logout = async (dispatch) => {
	const loggedUserJSON = window.localStorage.getItem('loggedUser');
	if (!loggedUserJSON) {
		throw new Error('No user logged in');
	}

	const loggedUser = JSON.parse(loggedUserJSON);
	const refreshToken = loggedUser.tokens.refresh.token;

	await axios.post(`${URL}/logout`, { refreshToken });

	window.localStorage.removeItem('loggedUser');
	dispatch(setUser(null));
};

const register = async (credentials, dispatch) => {
	try {
		const response = await axios.post(`${URL}/register`, credentials);
		window.localStorage.setItem(
			'loggedUser',
			JSON.stringify(response.data),
		);
		const { user, tokens } = response.data;
		setToken(tokens.access.token);
		dispatch(setUser(user));
		return response.data;
	} catch (error) {
		throw new Error(
			error.response ? error.response.data.message : 'Network Error',
		);
	}
};

export { login, register, logout };
