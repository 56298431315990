import { orange } from '@mui/material/colors';
import { createTheme, alpha } from '@mui/material/styles';

const customTheme = createTheme();

const black = {
	50: '#f2f2f2',
	100: '#e6e6e6',
	200: '#cccccc',
	300: '#b3b3b3',
	400: '#999999',
	500: '#1E1F1E', // Musta
	600: '#666666',
	700: '#4d4d4d',
	800: '#333333',
	900: '#1a1a1a',
};

const light = {
	50: '#ffffff',
	100: '#fefefe',
	200: '#fdfdfd',
	300: '#fcfcfc',
	400: '#fbfbfb',
	500: '#F8F4E3', // Vaalea
	600: '#f7f7f7',
	700: '#f6f6f6',
	800: '#f5f5f5',
	900: '#f4f4f4',
};

const getBrand = (userRole) => {
	if (userRole === 'student') {
		return {
			50: '#fff4e6',
			100: '#ffe8cc',
			200: '#ffdbb3',
			300: '#ffcc99',
			400: '#ffb366',
			500: '#FA7921', // Oranssi
			600: '#ff9933',
			700: '#ff8000',
			800: '#e67300',
			900: '#cc6600',
		};
	} else {
		// Oletusarvo (company tai mikä tahansa muu)
		return {
			50: '#f3e8ff',
			100: '#e6d1ff',
			200: '#d9baff',
			300: '#cc9eff',
			400: '#bf82ff',
			500: '#7C55E5', // Violetti
			600: '#a64dff',
			700: '#9933ff',
			800: '#8c1aff',
			900: '#8000ff',
		};
	}
};

export const pink = {
	50: '#fff5ff',
	100: '#ffebff',
	200: '#ffd9fc',
	300: '#ffccff',
	400: '#ffb3ff',
	500: '#ff99ff',
	600: '#ff80ff',
	700: '#ff66ff',
	800: '#ff4dff',
	900: '#ff33ff',
};

//oranssi (opiskelijoiden pääväri)

//violetti (yritysten pääväri)

//pinkki

//tausta #F8F4E3

//teksti #1E1F1E

export const gray = {
	50: 'hsl(220, 60%, 99%)',
	100: 'hsl(220, 35%, 94%)',
	200: 'hsl(220, 35%, 88%)',
	300: 'hsl(220, 25%, 80%)',
	400: 'hsl(220, 20%, 65%)',
	500: 'hsl(220, 20%, 42%)',
	600: 'hsl(220, 25%, 35%)',
	700: 'hsl(220, 25%, 25%)',
	800: 'hsl(220, 25%, 10%)',
	900: 'hsl(220, 30%, 5%)',
};

export const green = {
	50: 'hsl(120, 80%, 98%)',
	100: 'hsl(120, 75%, 94%)',
	200: 'hsl(120, 75%, 87%)',
	300: 'hsl(120, 61%, 77%)',
	400: 'hsl(120, 44%, 53%)',
	500: 'hsl(120, 59%, 30%)',
	600: 'hsl(120, 70%, 25%)',
	700: 'hsl(120, 75%, 16%)',
	800: 'hsl(120, 84%, 10%)',
	900: 'hsl(120, 87%, 6%)',
};

export const red = {
	50: 'hsl(0, 100%, 97%)',
	100: 'hsl(0, 92%, 90%)',
	200: 'hsl(0, 94%, 80%)',
	300: 'hsl(0, 90%, 65%)',
	400: 'hsl(0, 90%, 40%)',
	500: 'hsl(0, 90%, 30%)',
	600: 'hsl(0, 91%, 25%)',
	700: 'hsl(0, 94%, 20%)',
	800: 'hsl(0, 95%, 16%)',
	900: 'hsl(0, 93%, 12%)',
};

const getDesignTokens = (userRole) => {
	const brand = getBrand(userRole);

	return {
		palette: {
			mode: 'light',
			primary: {
				light: brand[200],
				main: brand[500],
				dark: brand[800],
				contrastText: light[50],
			},
			background: {
				default: light[500],
				paper:
					userRole === 'student'
						? `${alpha(brand[200], 0.2)}`
						: `${alpha(orange[200], 0.2)}`,
			},
			text: {
				primary: black[500],
				secondary: black[700],
			},
			action: {
				selected:
					userRole === 'student'
						? `${alpha(brand[200], 0.2)}`
						: `${alpha(orange[200], 0.2)}`,
			},
		},
		typography: {
			fontFamily: ['Clash Display', 'sans-serif'].join(','),
			h1: {
				fontSize: customTheme.typography.pxToRem(60),
				fontWeight: 600,
				lineHeight: 1.2,
				letterSpacing: -0.5,
			},
			h2: {
				fontSize: customTheme.typography.pxToRem(48),
				fontWeight: 600,
				lineHeight: 1.2,
			},
			h3: {
				fontSize: customTheme.typography.pxToRem(42),
				lineHeight: 1.2,
			},
			h4: {
				fontSize: customTheme.typography.pxToRem(36),
				fontWeight: 500,
				lineHeight: 1.5,
			},
			h5: {
				fontSize: customTheme.typography.pxToRem(20),
				fontWeight: 600,
			},
			h6: {
				fontSize: customTheme.typography.pxToRem(18),
			},
			subtitle1: {
				fontSize: customTheme.typography.pxToRem(18),
			},
			subtitle2: {
				fontSize: customTheme.typography.pxToRem(16),
			},
			body1: {
				fontSize: customTheme.typography.pxToRem(15),
				fontWeight: 400,
			},
			body2: {
				fontSize: customTheme.typography.pxToRem(14),
				fontWeight: 400,
			},
			caption: {
				fontSize: customTheme.typography.pxToRem(12),
				fontWeight: 400,
			},
		},
		shape: {
			borderRadius: 12,
		},
	};
};

export default function getLPTheme(userRole) {
	const brand = getBrand(userRole);

	return {
		...getDesignTokens(userRole),
		components: {
			MuiAccordion: {
				defaultProps: {
					elevation: 0,
					disableGutters: true,
				},
				styleOverrides: {
					root: ({ theme }) => ({
						padding: 8,
						overflow: 'clip',
						backgroundColor: 'hsl(0, 0%, 100%)',
						border: '1px solid',
						borderColor: gray[100],
						':before': {
							backgroundColor: 'transparent',
						},
						'&:first-of-type': {
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10,
						},
						'&:last-of-type': {
							borderBottomLeftRadius: 10,
							borderBottomRightRadius: 10,
						},
						...theme.applyStyles('dark', {
							backgroundColor: gray[900],
							borderColor: gray[800],
						}),
					}),
				},
			},
			MuiAccordionSummary: {
				styleOverrides: {
					root: ({ theme }) => ({
						border: 'none',
						borderRadius: 8,
						'&:hover': { backgroundColor: gray[100] },
						'&:focus-visible': { backgroundColor: 'transparent' },
						...theme.applyStyles('dark', {
							'&:hover': { backgroundColor: gray[800] },
						}),
					}),
				},
			},
			MuiAccordionDetails: {
				styleOverrides: {
					root: { mb: 20, border: 'none' },
				},
			},
			MuiButtonBase: {
				defaultProps: {
					disableTouchRipple: true,
					disableRipple: true,
				},
				styleOverrides: {
					root: {
						boxSizing: 'border-box',
						transition: 'all 100ms ease',
						'&:focus-visible': {
							outline: `3px solid ${alpha(brand[400], 0.5)}`,
							outlineOffset: '2px',
						},
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: ({ theme }) => ({
						boxShadow: 'none',
						borderRadius: theme.shape.borderRadius,
						textTransform: 'none',
						variants: [
							{
								props: {
									size: 'small',
								},
								style: {
									height: '2rem', // 32px
									padding: '0 0.5rem',
								},
							},
							{
								props: {
									size: 'medium',
								},
								style: {
									height: '2.5rem', // 40px
								},
							},
							{
								props: {
									color: 'primary',
									variant: 'contained',
								},
								style: {
									color: 'white',
									backgroundColor: brand[300],
									border: `1px solid ${brand[500]}`,
									'&:hover': {
										backgroundColor: brand[200],
										boxShadow: 'none',
									},
									'&:active': {
										backgroundColor: brand[200],
										boxShadow: 'none',
									},
								},
							},
							{
								props: {
									variant: 'outlined',
								},
								style: {
									color: brand[700],
									backgroundColor: 'transparent',
									borderColor: alpha(brand[300], 0.8),
									boxShadow: 'none',
									'&:hover': {
										backgroundColor: 'transparent',
										borderColor: alpha(brand[200], 0.5),
										boxShadow: 'none',
									},
									'&:active': {
										backgroundColor: 'transparent',
										boxShadow: 'none',
										backgroundImage: 'none',
									},
									...theme.applyStyles('dark', {
										color: brand[300],
										backgroundColor: 'transparent',
										borderColor: brand[300],
										boxShadow: 'none',
										'&:hover': {
											backgroundColor: 'transparent',
											borderColor: brand[200],
											boxShadow: 'none',
											color: brand[200],
										},
										'&:active': {
											backgroundColor: 'transparent',
											boxShadow: 'none',
											backgroundImage: 'none',
										},
									}),
								},
							},
							{
								props: {
									color: 'secondary',
									variant: 'outlined',
								},
								style: {
									backgroundColor: 'transparent',
									borderColor: alpha(gray[300], 0.5),
									color: gray[700],
									'&:hover': {
										backgroundColor: 'transparent',
										borderColor: alpha(gray[300], 0.5),
										boxShadow: 'none',
									},
									'&:active': {
										backgroundColor: 'transparent',
										boxShadow: 'none',
										backgroundImage: 'none',
									},
									...theme.applyStyles('dark', {
										color: gray[300],
										backgroundColor: 'transparent',
										borderColor: alpha(gray[700], 0.5),
										boxShadow: 'none',
										'&:hover': {
											backgroundColor: 'transparent',
											borderColor: alpha(gray[700], 0.5),
											boxShadow: 'none',
										},
										'&:active': {
											backgroundColor: 'transparent',
											boxShadow: 'none',
											backgroundImage: 'none',
										},
									}),
								},
							},
							{
								props: {
									color: 'primary',
									variant: 'text',
								},
								style: {
									color: brand[700],
									'&:hover': {
										backgroundColor: 'transparent',
									},
									...theme.applyStyles('dark', {
										color: brand[200],
										'&:hover': {
											backgroundColor: 'transparent',
										},
									}),
								},
							},
							{
								props: {
									color: 'info',
									variant: 'text',
								},
								style: {
									color: black[500],
									'&:hover': {
										backgroundColor: 'transparent',
									},
									...theme.applyStyles('dark', {
										color: gray[500],
										'&:hover': {
											backgroundColor: 'transparent',
										},
									}),
								},
							},
						],
					}),
				},
			},
			MuiCard: {
				styleOverrides: {
					root: ({ theme }) => ({
						transition: 'all 100ms ease',
						backgroundColor: alpha(brand[100], 0.5),
						borderRadius: theme.shape.borderRadius,
						border: `1px solid ${alpha(brand[700], 0.5)}`,
						boxShadow: 'none',
						...theme.applyStyles('dark', {
							backgroundColor: alpha(orange[100], 0.6),
							border: `1px solid ${alpha(orange[700], 0.3)}`,
						}),
						variants: [
							{
								props: {
									variant: 'outlined',
								},
								style: {
									border: `1px solid ${gray[200]}`,
									boxShadow: 'none',
									background: `linear-gradient(to bottom, hsl(0, 0%, 100%), ${gray[50]})`,
									...theme.applyStyles('dark', {
										border: `1px solid ${alpha(gray[700], 0.4)}`,
										boxShadow: 'none',
										background: `linear-gradient(to bottom, ${gray[900]}, ${alpha(
											gray[800],
											0.5,
										)})`,
									}),
								},
							},
						],
					}),
				},
			},
			MuiChip: {
				styleOverrides: {
					root: ({ theme }) => ({
						py: 1.5,
						px: 0.5,
						border: '1px solid',
						borderColor: brand[100],
						fontWeight: 600,
						backgroundColor: brand[50],
						'&:hover': {
							backgroundColor: brand[500],
						},
						'&:focus-visible': {
							borderColor: brand[300],
							backgroundColor: brand[200],
						},
						'& .MuiChip-label': {
							color: brand[500],
						},
						'& .MuiChip-icon': {
							color: brand[500],
						},
						...theme.applyStyles('dark', {
							borderColor: `${alpha(brand[500], 0.2)}`,
							backgroundColor: `${alpha(brand[900], 0.5)}`,
							'&:hover': {
								backgroundColor: brand[600],
							},
							'&:focus-visible': {
								borderColor: brand[500],
								backgroundColor: brand[800],
							},
							'& .MuiChip-label': {
								color: brand[200],
							},
							'& .MuiChip-icon': {
								color: brand[200],
							},
						}),
					}),
				},
			},
			MuiDivider: {
				styleOverrides: {
					root: ({ theme }) => ({
						borderColor: `${alpha(gray[200], 0.8)}`,
						...theme.applyStyles('dark', {
							borderColor: `${alpha(gray[700], 0.4)}`,
						}),
					}),
				},
			},
			MuiFormLabel: {
				styleOverrides: {
					root: ({ theme }) => ({
						typography: theme.typography.caption,
						marginBottom: 8,
					}),
				},
			},
			MuiIconButton: {
				styleOverrides: {
					root: ({ theme }) => ({
						color: brand[500],
						'&:hover': {
							backgroundColor: alpha(brand[300], 0.3),
							borderColor: brand[200],
						},
						...theme.applyStyles('dark', {
							color: brand[200],
							'&:hover': {
								backgroundColor: alpha(brand[600], 0.3),
								borderColor: brand[700],
							},
						}),
						variants: [
							{
								props: {
									size: 'small',
								},
								style: {
									height: '2rem',
									width: '2rem',
								},
							},
							{
								props: {
									size: 'medium',
								},
								style: {
									height: '2.5rem',
									width: '2.5rem',
								},
							},
						],
					}),
				},
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						border: 'none',
					},
				},
			},
			MuiLink: {
				defaultProps: {
					underline: 'none',
				},
				styleOverrides: {
					root: ({ theme }) => ({
						color: brand[700],
						fontWeight: 500,
						position: 'relative',
						textDecoration: 'none',
						'&::before': {
							content: '""',
							position: 'absolute',
							width: 0,
							height: '1px',
							bottom: 0,
							left: 0,
							backgroundColor: brand[200],
							opacity: 0.7,
							transition: 'width 0.3s ease, opacity 0.3s ease',
						},
						'&:hover::before': {
							width: '100%',
							opacity: 1,
						},
						'&:focus-visible': {
							outline: `3px solid ${alpha(brand[500], 0.5)}`,
							outlineOffset: '4px',
							borderRadius: '2px',
						},
						...theme.applyStyles('dark', {
							color: brand[200],
						}),
					}),
				},
			},
			MuiMenuItem: {
				styleOverrides: {
					root: ({ theme }) => ({
						paddingRight: 6,
						paddingLeft: 6,
						color: gray[700],
						fontSize: '0.875rem',
						fontWeight: 500,
						borderRadius: theme.shape.borderRadius,
						...theme.applyStyles('dark', {
							color: gray[200],
						}),
					}),
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					notchedOutline: {
						border: 'none',
					},
					input: {
						paddingLeft: 10,
					},
					root: ({ theme }) => ({
						'input:-webkit-autofill': {
							WebkitBoxShadow: `0 0 0 1000px ${brand[100]} inset, 0 0 0 1px ${brand[200]}`,
							maxHeight: '4px',
							borderRadius: '8px',
						},
						'& .MuiInputBase-input': {
							fontSize: '1rem',
							'&::placeholder': {
								opacity: 0.7,
								color: gray[500],
							},
						},
						boxSizing: 'border-box',
						flexGrow: 1,
						height: '40px',
						borderRadius: theme.shape.borderRadius,
						border: '1px solid',
						borderColor: alpha(gray[300], 0.8),
						boxShadow: '0 0 0 1.5px hsla(210, 0%, 0%, 0.02) inset',
						transition: 'border-color 120ms ease-in',
						backgroundColor: alpha(gray[100], 0.4),
						'&:hover': {
							borderColor: brand[300],
						},
						'&.Mui-focused': {
							outline: `3px solid ${alpha(brand[500], 0.5)}`,
							outlineOffset: '2px',
							borderColor: brand[400],
						},
						...theme.applyStyles('dark', {
							'input:-webkit-autofill': {
								WebkitBoxShadow: `0 0 0 1000px ${brand[900]} inset, 0 0 0 1px ${brand[600]}`,
								maxHeight: '6px',
								borderRadius: '8px',
							},
							'& .MuiInputBase-input': {
								fontSize: '1rem',
								'&::placeholder': {
									opacity: 1,
									color: gray[500],
								},
							},
							borderColor: alpha(gray[700], 0.5),
							boxShadow: '0 0 0 1.5px hsl(210, 0%, 0%) inset',
							backgroundColor: alpha(gray[900], 0.8),
							transition: 'border-color 120ms ease-in',
							'&:hover': {
								borderColor: brand[300],
							},
							'&.Mui-focused': {
								borderColor: brand[400],
								outline: `3px solid ${alpha(brand[500], 0.5)}`,
								outlineOffset: '2px',
							},
						}),
						variants: [
							{
								props: {
									color: 'error',
								},
								style: {
									borderColor: red[200],
									color: red[500],
									'& + .MuiFormHelperText-root': {
										color: red[500],
									},
									...theme.applyStyles('dark', {
										borderColor: red[700],
										color: red[300],
										'& + .MuiFormHelperText-root': {
											color: red[300],
										},
									}),
								},
							},
						],
					}),
				},
			},
			MuiPaper: {
				defaultProps: {
					elevation: 0,
				},
			},
			MuiStack: {
				defaultProps: {
					useFlexGap: true,
				},
			},
			MuiSwitch: {
				styleOverrides: {
					root: ({ theme }) => ({
						boxSizing: 'border-box',
						width: 36,
						height: 24,
						padding: 0,
						transition: 'background-color 100ms ease-in',
						'&:hover': {
							'& .MuiSwitch-track': {
								backgroundColor: brand[600],
							},
						},
						'& .MuiSwitch-switchBase': {
							'&.Mui-checked': {
								transform: 'translateX(13px)',
							},
						},
						'& .MuiSwitch-track': {
							borderRadius: 50,
						},
						'& .MuiSwitch-thumb': {
							boxShadow: '0 0 2px 2px hsla(220, 0%, 0%, 0.2)',
							backgroundColor: 'hsl(0, 0%, 100%)',
							width: 16,
							height: 16,
							margin: 2,
						},
						...theme.applyStyles('dark', {
							width: 36,
							height: 24,
							padding: 0,
							transition: 'background-color 100ms ease-in',
							'&:hover': {
								'& .MuiSwitch-track': {
									backgroundColor: brand[600],
								},
							},
							'& .MuiSwitch-switchBase': {
								'&.Mui-checked': {
									transform: 'translateX(13px)',
								},
							},
							'& .MuiSwitch-thumb': {
								boxShadow: '0 0 2px 2px hsla(220, 0%, 0%, 0.2)',
								backgroundColor: 'hsl(0, 0%, 100%)',
								width: 16,
								height: 16,
								margin: 2,
							},
						}),
					}),
					switchBase: {
						height: 24,
						width: 24,
						padding: 0,
						color: 'hsl(0, 0%, 100%)',
						'&.Mui-checked + .MuiSwitch-track': {
							opacity: 1,
						},
					},
				},
			},
			MuiToggleButtonGroup: {
				styleOverrides: {
					root: ({ theme }) => ({
						borderRadius: theme.shape.borderRadius,
						border: '1px solid',
						borderColor: brand[500],
					}),
				},
			},
			MuiToggleButton: {
				styleOverrides: {
					root: ({ theme }) => ({
						padding: '10px 14px',
						textTransform: 'none',
						'&:hover': {
							backgroundColor: 'transparent',
						},
						borderRadius: theme.shape.borderRadius,
						fontWeight: 500,
						...theme.applyStyles('dark', {
							color: gray[400],
							'&.Mui-selected': { color: black[500] },
						}),
					}),
				},
			},
			MuiModal: {
				styleOverrides: {
					root: {
						'&:focus': {
							outline: 'none',
							boxShadow:
								'0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5), 0 0 30px 10px hsla(210, 100%, 80%, 0.4)',
						},
					},
				},
			},
			MuiBox: {
				styleOverrides: {
					root: {
						'&:focus': {
							outline: 'none',
						},
					},
				},
			},
		},
	};
}
