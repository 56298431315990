import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { scrollToSection } from './AppAppBar';
import { pink } from '../getLPTheme';
import { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const StyledBox = styled('div')(({ theme }) => ({
	alignSelf: 'center',
	width: '100%',
	position: 'relative',
	paddingTop: '56.25%',
	marginTop: theme.spacing(4),
	borderRadius: theme.shape.borderRadius,
	outline: '1px solid',
	outlineColor: 'hsla(220, 25%, 80%, 0.5)',
	overflow: 'hidden',

	backgroundImage: `url(${'http://www.lenz.fi/wp-content/uploads/2024/11/Lenz-platform-uusivisu-scaled.jpg'})`,
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'right center',

	[theme.breakpoints.up('sm')]: {
		marginTop: theme.spacing(6),
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	[theme.breakpoints.down('sm')]: {
		marginTop: theme.spacing(3),
		height: 'auto',
		paddingTop: '45%',
		minHeight: 'unset',
		backgroundSize: '100% auto',
	},
}));

const PrivacyDialog = ({ open, onClose }) => (
	<Dialog
		open={open}
		onClose={onClose}
		maxWidth="sm"
		fullWidth
		PaperProps={{
			sx: {
				bgcolor: '#fff',
				boxShadow: 24,
				p: 2,
				backgroundImage: 'none',
				opacity: 1,
			},
		}}
		BackdropProps={{
			sx: {
				backgroundColor: 'rgba(0, 0, 0, 0.8)',
			},
		}}
		sx={{
			zIndex: (theme) => theme.zIndex.modal + 1,
		}}
	>
		<DialogTitle>Tietosuoja ja käyttöehdot</DialogTitle>
		<DialogContent>
			<Typography variant="body2" paragraph>
				Keräämme ja käsittelemme henkilötietojasi seuraavasti:
			</Typography>
			<Typography variant="body2" component="div">
				<ul>
					<li>
						Keräämme sähköpostiosoitteesi odotuslistalle liittymistä
						varten
					</li>
					<li>
						Käytämme tietoja vain ilmoittaaksemme palvelun
						käyttöönottamisesta
					</li>
					<li>Emme jaa tietojasi kolmansille osapuolille</li>
					<li>
						Voit milloin tahansa pyytää tietojesi poistamista:
						info@lenz.fi
					</li>
					<li>
						Tietoja säilytetään EU/ETA-alueella Formspree-palvelussa
					</li>
				</ul>
			</Typography>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose}>Sulje</Button>
		</DialogActions>
	</Dialog>
);

const StyledVideo = styled('video')(({ theme }) => ({
	alignSelf: 'center',
	width: '100%',
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	outline: '1px solid',
	outlineColor: 'hsla(220, 25%, 80%, 0.5)',
	overflow: 'hidden',
	objectFit: 'cover',
	height: 'auto',

	[theme.breakpoints.up('sm')]: {
		marginTop: theme.spacing(6),
	},
	[theme.breakpoints.down('sm')]: {
		marginTop: theme.spacing(3),
	},
}));

export default function Hero({ userRole }) {
	const [state, handleSubmit] = useForm('myzypkvv');
	const [email, setEmail] = useState('');
	const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!acceptedTerms) {
			alert('Hyväksy käyttöehdot jatkaaksesi');
			return;
		}
		handleSubmit(e);
	};

	return (
		<Box
			id="hero"
			sx={(theme) => ({
				width: '100%',
				backgroundRepeat: 'no-repeat',
				backgroundImage:
					'radial-gradient(ellipse 80% 50% at 50% -20%, #FFD9FC, transparent)',
			})}
		>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					pt: { xs: 14, sm: 20 },
					pb: { xs: 8, sm: 12 },
				}}
			>
				<Stack
					spacing={2}
					useFlexGap
					sx={{
						alignItems: 'center',
						width: { xs: '100%', sm: '75%' },
					}}
				>
					<Typography
						variant="h1"
						sx={{
							fontSize: {
								xs: '2rem',
								sm: '3rem',
								md: '4rem',
							},
							textAlign: 'center',
							lineHeight: { xs: 1.2, sm: 1.3 },
							mb: { xs: 2, sm: 3 },
						}}
					>
						{userRole === 'company' ? 'Lenz' : 'Etsitkö'}
						&nbsp;
						<Typography
							component="span"
							variant="h1"
							sx={(theme) => ({
								fontSize: 'inherit',
								color: 'primary.main',
								...theme.applyStyles('dark', {
									color: 'primary.dark',
								}),
							})}
						>
							{userRole === 'company'
								? 'Osaajapalvelut'
								: 'joustavaa työtä'}
						</Typography>
						&nbsp;
						{userRole === 'company' ? '' : 'opintojen ohelle?'}
					</Typography>

					<Typography
						sx={{
							textAlign: 'center',
							color: 'text.secondary',
							width: { xs: '100%', sm: '100%', md: '80%' },
						}}
					>
						{userRole === 'company' ? (
							<>
								Yhdistämme huomisen huippuosaajat
								innovatiivisiin yrityksiin edistyneellä
								teknologialla. Löydä asiantuntija nopeasti ja
								helposti! Alkaen 40€/h.
							</>
						) : (
							<>
								Hyödynnä taitosi ja tee merkityksellistä työtä
								joustavasti opintojesi ohella. Löydä inspiroivia
								projekteja, kartuta arvokasta kokemusta ja
								ansaitse rahaa opiskellessasi!
							</>
						)}
					</Typography>

					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						spacing={1}
						useFlexGap
						sx={{
							pt: 2,
							width: { xs: '100%', sm: 'auto' },
						}}
					>
						<form onSubmit={handleFormSubmit}>
							<Stack spacing={1} alignItems="center">
								<input
									type="hidden"
									name="type"
									value={userRole}
								/>
								<input
									type="hidden"
									name="accepted_terms"
									value={acceptedTerms.toString()}
								/>

								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									spacing={1}
								>
									<TextField
										id="email"
										name="email"
										size="small"
										variant="outlined"
										placeholder="Sähköpostiosoitteesi"
										value={email}
										onChange={handleEmailChange}
										disabled={state.submitting}
										error={
											state.errors &&
											Object.keys(state.errors).length > 0
										}
										inputProps={{
											'aria-label': 'Sähköpostiosoite',
											autoComplete: 'off',
										}}
										fullWidth
									/>
									<Button
										variant="contained"
										type="submit"
										disabled={
											state.submitting || !acceptedTerms
										}
										fullWidth
									>
										{state.submitting
											? 'Lähetetään...'
											: 'Liity odotuslistalle'}
									</Button>
								</Stack>

								<FormControlLabel
									control={
										<Checkbox
											checked={acceptedTerms}
											onChange={(e) =>
												setAcceptedTerms(
													e.target.checked,
												)
											}
											size="small"
										/>
									}
									label={
										<Typography variant="caption">
											Hyväksyn{' '}
											<Link
												href="#"
												onClick={(e) => {
													e.preventDefault();
													setPrivacyDialogOpen(true);
												}}
											>
												tietosuojakäytännön ja
												käyttöehdot
											</Link>
										</Typography>
									}
								/>

								<ValidationError
									prefix="Email"
									field="email"
									errors={state.errors}
								/>

								{state.succeeded && (
									<Typography sx={{ color: 'success.main' }}>
										Kiitos liittymisestä odotuslistalle!
									</Typography>
								)}
							</Stack>
						</form>
					</Stack>

					<PrivacyDialog
						open={privacyDialogOpen}
						onClose={() => setPrivacyDialogOpen(false)}
					/>

					{userRole === 'company' ? (
						<StyledVideo
							autoPlay
							muted
							loop
							playsInline
							poster="/images/platform-demo.png"
						>
							<source
								src="https://www.lenz.fi/wp-content/uploads/2024/11/LenzPlatform-demo.mp4"
								type="video/mp4"
							/>
							<StyledBox id="image" />
						</StyledVideo>
					) : (
						<StyledBox id="image" />
					)}
				</Stack>
			</Container>
		</Box>
	);
}
