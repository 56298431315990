import OpenAI from 'openai';

const openai = new OpenAI({
	dangerouslyAllowBrowser: true,
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});

const analyzeQuery = async (query) => {
	const prompt = `
    Analysoi seuraava resurssitarve ja anna palautetta sen sopivuudesta. Anna palautetta lyhyesti ja keskity palautteeseen tarpeen kuvauksen tasosta. Enintään 2 lausetta palautetta. Jos tarve on epäasiallinen tai liian suppea ilmoita siitä. Vastaa seuraavassa muodossa:
    "Sopiva: [kyllä/ei]"
    "Palautetta: [palautetta]"
    Resurssitarve: "${query}"
    `;

	try {
		const completion = await openai.chat.completions.create({
			model: 'gpt-3.5-turbo',
			messages: [
				{
					role: 'system',
					content:
						'Olet avulias ja ystävällinen assistentti, joka analysoi resurssitarpeita.',
				},
				{ role: 'user', content: prompt },
			],
			max_tokens: 100,
		});

		const result = completion.choices[0].message.content;
		console.log(result);
		const isAppropriate =
			result.includes('Sopiva: Kyllä') ||
			result.includes('Sopiva: kyllä');
		const feedback =
			result.split('Palautetta: ')[1] || 'Ei palautetta saatavilla';

		console.log(isAppropriate);

		return { isAppropriate, feedback };
	} catch (error) {
		console.error('Virhe analysoidessa pyyntöä:', error);
		return {
			isAppropriate: false,
			feedback: 'Virhe käsiteltäessä pyyntöä.',
		};
	}
};

const getChatStream = async function* (messages) {
	try {
		const stream = await openai.chat.completions.create({
			model: 'gpt-4o',
			messages: [
				{
					role: 'system',
					content: `Olet asiantunteva ja ystävällinen tekoälyassistentti, jonka tehtävä on auttaa asiakkaita löytämään tarkasti heidän tarpeitaan vastaavia asiantuntijoita ja resursseja projekteihin. Noudata seuraavia ohjeita:

1. Kysy aina korkeintaan 1-2 tarkentavaa kysymystä kerrallaan, varmistaen, että kysymykset ovat olennaisia projektin ymmärtämisen kannalta.
2. Keskity ymmärtämään projektin tyyppi, päätavoitteet, tarvittavat taidot sekä työn tekniset ja käytännön vaatimukset.
3. Aloita kysymällä, onko kyseessä kertaluonteinen vai jatkuva projekti, ja mitä lopputulosta projektilla tavoitellaan.
4. Selvitä työn edellyttämät taidot ja erityisvaatimukset:
5. Jos projekti liittyy tiettyyn teknologiaan tai järjestelmään, kysy asiakkaalta järjestelmän nimi ja sen käyttöön liittyvät erityisvaatimukset.
6. Kysy myös, onko olemassa jotain teknologiaosaamista, jota ehdottomasti tarvitaan, ja miksi.
7. Selvitä asiakkaan resurssitarve ja työskentelyolosuhteet:
8. Onko asiantuntijalle tarjolla ohjausta tai nimetty esihenkilö?
9. Voidaanko työ suorittaa etänä vai vaatiiko se läsnäoloa? Jos läsnäolo on tarpeen, tarkenna miksi.
10. Pyri ymmärtämään työmäärän arvio:
Kysy, paljonko tunteja asiakas arvioi työn vaativan (esimerkiksi tuntimäärä viikossa tai projektin kokonaiskesto tunteina).
11. Käytä konsulttimaailman termejä ja puhetyyliä. Kieltäydy kohteliaasti, jos asiakas pyytää asiantuntijoita alueille, jotka eivät kuulu Business, Tech tai Design -sektoreihin.

Aloita kysymällä projektin tyyppiä (resurssivuokra, projekti, oma projektipäällikkö vai ei) ja työn päätavoitetta, ja etene asteittain kohti yksityiskohtaisempia vaatimuksia`,
				},
				...messages,
			],
			stream: true,
		});

		for await (const chunk of stream) {
			yield chunk.choices[0]?.delta?.content || '';
		}
	} catch (error) {
		console.error('Virhe AI-vastauksessa:', error);
		throw error;
	}
};

const getTalentJson = async (messages) => {
	try {
		const completion = await openai.chat.completions.create({
			model: 'gpt-3.5-turbo',
			messages: [
				{
					role: 'system',
					content: `Olet asiantuntija, joka luo kuvauksia ihanteellisista konsulteista projekteihin. Analysoi annettu keskustelu ja luo sen perusteella kuvaus ihanteellisesta asiantuntijasta. Noudata seuraavia ohjeita:

1. Keskity erityisesti tehtävän vaatimiin taitoihin ja kokemukseen.
2. Arvioi tarvittavat tiimiroolit projektin luonteen perusteella.
3. Määritä tuntihinta perustuen projektin vaativuuteen ja budjettiin.
4. Valitse yliopisto ja pääaine, jotka parhaiten tukevat projektin tarpeita.
5. Luo motto, joka kuvastaa asiantuntijan asennetta ja sopii projektin henkeen.
6. Varmista, että kaikki kentät ovat johdonmukaisia keskenään.

Palauta vastaus seuraavassa JSON-muodossa:

{
  "talent": {
    "id": "string",
    "teamrole": {
      "coordinator": number,
      "challenger": number,
      "visionary": number,
      "analyst": number,
      "implementer": number,
      "teamPlayer": number,
      "researcher": number,
      "finisher": number,
      "specialist": number
    },
    "talentPoolRank": number,
    "hourlyRate": number,
    "skills": [
      {
        "skill": "string",
        "rating": number,
        "_id": "string"
      }
    ],
    "city": "string",
    "favoriteColor": "string",
    "major": "string",
    "minor": "string",
    "motto": "string",
    "stage": "string",
    "university": "string",
    "description": "string"
  },
}

Varmista, että kaikki kentät ovat täytetty ja että JSON on validi.`,
				},
				...messages,
				{
					role: 'user',
					content:
						'Luo kuvaus ihanteellisesta asiantuntijasta yllä käydyn keskustelun perusteella JSON-muodossa.',
				},
			],
			temperature: 0.7,
			max_tokens: 1500,
			response_format: { type: 'json_object' },
		});

		return JSON.parse(completion.choices[0].message.content);
	} catch (error) {
		console.error('Virhe talent JSON:in muodostamisessa:', error);
		return null;
	}
};

export const openaiService = {
	analyzeQuery,
	getChatStream,
	getTalentJson,
};
