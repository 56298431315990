import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, List, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../features/User/userSlice';
import MenuItemComponent from './MenuItemComponent';
import { menuItemsConfig } from '../util/utils';

const Sidebar = ({ drawerOpen, drawerToggle }) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const drawerWidth = 260;
	const user = useSelector(selectUser);

	const handleMenuItemClick = () => {
		if (isMobile) {
			drawerToggle(); // Sulkee sivupalkin mobiilissa
		}
	};

	const menuItems = menuItemsConfig[user?.role] || [];

	const drawerContent = (
		<List component="nav" sx={{ gap: 2, padding: 2 }}>
			{menuItems.map((item) => (
				<MenuItemComponent
					key={item.to}
					to={item.to}
					icon={item.icon}
					primary={item.primary}
					onClick={() => {
						navigate(item.to);
						handleMenuItemClick();
					}}
				/>
			))}
		</List>
	);

	return (
		<Box
			component="nav"
			sx={{
				flexShrink: { md: 0 },
				width: isMobile ? 'auto' : drawerWidth,
			}}
			aria-label="navigation drawer"
		>
			<Drawer
				variant={isMobile ? 'temporary' : 'persistent'}
				anchor="left"
				open={drawerOpen}
				onClose={drawerToggle}
				ModalProps={{ keepMounted: true }}
				sx={{
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						height: 'calc(100% - 120px)',
						marginTop: '100px',
						backgroundColor: 'white',
						color: theme.palette.text.primary,
						borderRight: 'none',
						boxSizing: 'border-box',
					},
				}}
			>
				{drawerContent}
			</Drawer>
		</Box>
	);
};

export default Sidebar;
