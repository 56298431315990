import React from 'react';
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	ResponsiveContainer,
} from 'recharts';
import { Paper, Typography } from '@mui/material';

const PotentialRadarChart = ({ potentialTest, favoriteColor }) => {
	if (!potentialTest) return null;

	const radarChartData = [
		{
			subject: 'Tarkoitus ja\nkutsumus',
			arvo: potentialTest?.INNOSTUS?.tarkoitusJaKutsumus * 25 || 0,
		},
		{
			subject: 'Tavoitteellisuus\nja sisu',
			arvo: potentialTest?.INNOSTUS?.tavoitteellisuusJaSisu * 25 || 0,
		},
		{
			subject: 'Uteliaisuus ja\noppiminen',
			arvo:
				potentialTest?.INNOSTUS?.uteliaisuusJaOppimisorientoituneisuus *
					25 || 0,
		},
		{
			subject: 'Luovuus ja\navarakatseisuus',
			arvo: potentialTest?.LUOVUUS?.luovuusJaAvarakatseisuus * 25 || 0,
		},
		{
			subject: 'Kriittinen\najattelu',
			arvo:
				potentialTest?.LUOVUUS?.kriittinenAjattelukykyJaAnalyyttisyys *
					25 || 0,
		},
	];

	return (
		<Paper
			elevation={3}
			sx={{
				p: 3,
				height: { xs: '400px', md: '350px' },
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Typography variant="h6" align="center" gutterBottom>
				Potentiaali
			</Typography>
			<ResponsiveContainer width="100%" height={300}>
				<RadarChart
					cx="50%"
					cy="50%"
					outerRadius="65%"
					data={radarChartData}
				>
					<PolarGrid gridType="polygon" />
					<PolarAngleAxis
						dataKey="subject"
						tick={{ fontSize: '11px', fill: '#666' }}
					/>
					<PolarRadiusAxis angle={30} tick={{ fontSize: '10px' }} />
					<Radar
						name="Potentiaali"
						dataKey="arvo"
						stroke={favoriteColor}
						fill={favoriteColor}
						fillOpacity={0.6}
					/>
				</RadarChart>
			</ResponsiveContainer>
		</Paper>
	);
};

export default PotentialRadarChart;
