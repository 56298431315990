import React from 'react';
import { Dialog, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const VideoModal = ({ open, onClose, videoUrl }) => {
	if (!videoUrl) return null;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth={false}
			sx={{
				'& .MuiDialog-paper': {
					borderRadius: '24px',
					overflow: 'hidden',
					backgroundColor: '#000',
					position: 'relative',
					margin: { xs: '16px', md: '32px' },
					width: 'auto',
					maxWidth: '90vw',
					maxHeight: '90vh',
				},
				'& .MuiDialogContent-root': {
					padding: 0,
					'&::-webkit-scrollbar': {
						display: 'none',
					},
				},
			}}
		>
			<IconButton
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 16,
					top: 16,
					color: 'white',
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
					zIndex: 2,
					'&:hover': {
						backgroundColor: 'rgba(0, 0, 0, 0.7)',
					},
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<Box
					component="video"
					controls
					playsInline
					autoPlay
					sx={{
						display: 'block',
						maxWidth: '100%',
						maxHeight: '85vh',
						width: 'auto',
						height: 'auto',
					}}
					src={videoUrl}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default VideoModal;
