import React from 'react';
import { Card, Typography, Box } from '@mui/material';

const ApplicationCard = ({ application }) => {
	const handleClick = () => {
		console.log('Application clicked:', application);
	};

	return (
		<Card onClick={handleClick} sx={{ my: 1, p: 2 }}>
			<Typography variant="h6">Rekry: {application.title}</Typography>
			<Typography variant="subtitle1">
				{' '}
				Hakemus jätetty: {application.submitted_at}
			</Typography>
			<Typography variant="subtitle2">
				{' '}
				Pisteet: {application.score}
			</Typography>
			<Typography variant="subtitle2">
				{' '}
				Vaihe: {application.currentStage}
			</Typography>
		</Card>
	);
};

export default ApplicationCard;
