import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import {
	Grid,
	TextField,
	Typography,
	Box,
	InputAdornment,
	IconButton,
	Skeleton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TalentCard from './TalentCard';
import { talentService } from '../../../services';
import { debounce } from 'lodash';

// Yksinkertaistettu hakukomponentti
const SearchInput = ({ searchTerm, onChange, onSearchSubmit }) => (
	<Box sx={{ display: 'flex', mb: 4 }}>
		<TextField
			fullWidth
			placeholder="Etsi talentteja"
			variant="outlined"
			onChange={onChange}
			onKeyPress={(e) => e.key === 'Enter' && onSearchSubmit()}
			value={searchTerm}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton onClick={onSearchSubmit}>
							<SearchIcon />
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	</Box>
);

// Placeholder-komponentti latauksen aikana
const TalentPlaceholder = () => (
	<Grid item xs={12} sm={6} md={4}>
		<Skeleton
			variant="rectangular"
			height={250}
			sx={{ borderRadius: '8px' }}
		/>
	</Grid>
);

const TalentBrowser = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredTalents, setFilteredTalents] = useState([]);

	const { data, error, isLoading, refetch } = useQuery(
		['talentsData', searchTerm],
		() =>
			searchTerm
				? talentService.hybridSearch(searchTerm)
				: talentService.getTalents(),
		{
			enabled: false,
			onSuccess: handleSuccess,
		},
	);

	function handleSuccess(data) {
		if (searchTerm) {
			const validResults = data.filter(
				(result) => result.talent && result.score !== null,
			);
			setFilteredTalents(
				validResults.map((result) => ({
					...result.talent,
					score: result.score,
					vs_score: result.vs_score,
					fts_score: result.fts_score,
				})),
			);
		} else {
			setFilteredTalents(data.data.results);
		}
	}

	// Päivitetään hakukentän arvo normaalisti
	const handleSearchChange = (e) => setSearchTerm(e.target.value);

	// Debouncella varustettu hakufunktio, joka kutsuu refetch-funktiota
	const debouncedSearch = useCallback(
		debounce(() => refetch(), 300),
		[refetch],
	);

	useEffect(() => {
		if (searchTerm) {
			debouncedSearch();
		} else {
			refetch();
		}
	}, [searchTerm, debouncedSearch, refetch]);

	const handleSearchSubmit = () => {
		debouncedSearch.cancel(); // Peruuta debounce ja suorita haku välittömästi
		refetch();
	};

	if (error)
		return <Typography color="error">Virhe ladattaessa dataa</Typography>;

	return (
		<div>
			<SearchInput
				searchTerm={searchTerm}
				onChange={handleSearchChange}
				onSearchSubmit={handleSearchSubmit}
			/>

			<Grid container spacing={2} justifyContent="center">
				{isLoading ? (
					Array.from(new Array(6)).map((_, index) => (
						<TalentPlaceholder key={index} />
					))
				) : filteredTalents.length > 0 ? (
					filteredTalents.map((talent) => (
						<TalentCard
							key={talent.id}
							talent={talent}
							fts_score={talent.fts_score}
							vs_score={talent.vs_score}
						/>
					))
				) : (
					<Typography variant="body1" sx={{ mt: 4 }}>
						No talents found.
					</Typography>
				)}
			</Grid>
		</div>
	);
};

export default TalentBrowser;
