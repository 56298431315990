import axios from './axios';

const getApplicationsByRecruitmentId = async (id) => {
	const response = await axios.get(`/applications/recruitment/${id}`);
	return response;
};

const updateApplicationById = async (id, newObject) => {
	const response = await axios.patch(`/applications/${id}`, newObject);
	return response.data;
};

export { getApplicationsByRecruitmentId, updateApplicationById };
