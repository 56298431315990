import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
	name: 'notification',
	initialState: { error: null, success: null },
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
			state.success = null;
		},
		setSuccess: (state, action) => {
			state.success = action.payload;
			state.error = null;
		},
		clearNotification: (state) => {
			state.error = null;
			state.success = null;
		},
	},
});

export const { setError, setSuccess, clearNotification } =
	notificationSlice.actions;
export default notificationSlice.reducer;
