import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { setTalent } from 'features/Talent/talentSlice';
import { talentService } from 'services';

export const useTalentData = (talentId) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const { data, error, isLoading } = useQuery(
		['talentData', talentId],
		() => talentService.getTalent(talentId),
		{
			enabled: !!talentId,
			onSuccess: (data) => {
				dispatch(setTalent(data.data));
			},
		},
	);

	const updateMutation = useMutation(
		(updatedData) => talentService.updateTalentById(talentId, updatedData),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['talentData', talentId]);
			},
		},
	);

	return {
		talent: data?.data,
		error,
		isLoading,
		updateTalent: updateMutation.mutate,
	};
};
