import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

const MenuItemComponent = ({ to, icon: Icon, primary, onClick }) => (
	<ListItemButton
		component={Link}
		to={to}
		onClick={onClick} // Kutsutaan onClick-funktiota, kun käyttäjä klikkaa kohdetta
	>
		<ListItemIcon>
			<Icon />
		</ListItemIcon>
		<ListItemText primary={primary} />
	</ListItemButton>
);

export default MenuItemComponent;
