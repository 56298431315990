import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectIsTalent } from 'features/User/userSlice';
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	ResponsiveContainer,
} from 'recharts';
import { Paper, Typography } from '@mui/material';
import TeamRoles from 'components/TeamRoles';

const TeamRolesRadarChart = ({ teamRoles, favoriteColor }) => {
	const isTalent = useSelector(selectIsTalent);

	const teamRoleTitles = {
		coordinator: 'Koordinaattori',
		challenger: 'Haastaja',
		visionary: 'Visionääri',
		analyst: 'Analyytikko',
		implementer: 'Toteuttaja',
		teamPlayer: 'Tiimipelaaja',
		researcher: 'Tutkija',
		finisher: 'Viimeistelijä',
		specialist: 'Asiantuntija',
	};

	const radarChartData = useMemo(
		() =>
			Object.keys(teamRoleTitles).map((key) => ({
				subject: teamRoleTitles[key],
				A: teamRoles?.[key] || 0,
				fullMark: 100,
			})),
		[teamRoles],
	);

	return (
		<Paper
			elevation={3}
			sx={{
				p: 3,
				height: { xs: '400px', md: '350px' },
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Typography variant="h6" align="center" gutterBottom>
				Profiili
			</Typography>
			<ResponsiveContainer width="100%" height={300}>
				<RadarChart
					cx="50%"
					cy="50%"
					outerRadius="65%"
					data={radarChartData}
				>
					<PolarGrid gridType="polygon" />
					<PolarAngleAxis
						dataKey="subject"
						tick={{ fontSize: '11px', fill: '#666' }}
					/>
					<PolarRadiusAxis angle={30} tick={{ fontSize: '10px' }} />
					<Radar
						name="Tiimirooli"
						dataKey="A"
						stroke={favoriteColor}
						fill={favoriteColor}
						fillOpacity={0.6}
					/>
				</RadarChart>
			</ResponsiveContainer>
			{/* {isTalent && <TeamRoles teamRoleData={teamRoles} />} */}
		</Paper>
	);
};

export default TeamRolesRadarChart;
