import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUser, selectIsTalent } from 'features/User/userSlice';

export const useTalentId = () => {
	const { talentId: paramTalentId } = useParams();
	const currentUser = useSelector(selectUser);
	const isTalent = useSelector(selectIsTalent);

	return isTalent ? currentUser?.profileId : paramTalentId;
};
