import axios from './axios';

const getRecruits = async () => {
	const response = await axios.get(`/recruitments`);
	return response;
};

const getOpenRecruits = async () => {
	const response = await axios.get(`/recruitments/open`);
	return response;
};

const getRecruit = async (id) => {
	const response = await axios.get(`/recruitments/${id}`);
	return response;
};

const addRecruit = async (newObject) => {
	const response = await axios.post(`/recruitments`, newObject);
	return response.data;
};

const updateRecruitmentById = async (id, newObject) => {
	const response = await axios.patch(`/recruitments/${id}`, newObject);
	return response.data;
};

const updateRecruitmentStageById = async (id, newObject) => {
	const response = await axios.patch(`/recruitments/${id}/stage`, newObject);
	return response.data;
};

const updateRecruitmentStageNames = async (id, newObject) => {
	const response = await axios.patch(`/recruitments/${id}/stages`, newObject);
	return response.data;
};

export {
	getRecruits,
	getRecruit,
	addRecruit,
	updateRecruitmentById,
	updateRecruitmentStageById,
	updateRecruitmentStageNames,
	getOpenRecruits,
};
