import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { talentService } from '../../../services';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/User/userSlice';

const TalentsTable = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [searchTerm, setSearchTerm] = useState('');

	const currentUser = useSelector(selectUser);
	const isCompany = currentUser?.role === 'company';

	const { data, error, isLoading } = useQuery('talentsData', () =>
		talentService.getTalents(),
	);
	console.log(data);

	const filterOptions = useMemo(
		() => [
			{ label: '1', value: '1' },
			{ label: '2', value: '2' },
			{ label: '3', value: '3' },
		],
		[],
	);

	const columns = useMemo(
		() => [
			{
				accessorKey: 'user.name',
				header: 'Name',
			},
			{
				accessorKey: 'hourlyRate',
				filterFn: 'between',
				header: 'Tuntipalkka',
				size: 200,
				Cell: ({ cell }) => {
					const hourlyRate = cell.getValue();
					const displayRate = isCompany ? hourlyRate * 2 : hourlyRate;
					return (
						<Box
							component="span"
							sx={(theme) => ({
								backgroundColor:
									hourlyRate > 45
										? theme.palette.error.dark
										: hourlyRate >= 20 && hourlyRate < 45
											? theme.palette.warning.dark
											: theme.palette.success.dark,
								borderRadius: '0.25rem',
								color: '#fff',
								maxWidth: '9ch',
								p: '0.25rem',
							})}
						>
							{displayRate?.toLocaleString?.('en-US', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							})}
						</Box>
					);
				},
			},
			{
				accessorKey: 'major',
				header: 'Pääaine',
			},
			{
				accessorKey: 'university',
				header: 'Oppilaitos',
			},
			{
				accessorKey: 'talentPoolRank',
				header: 'TalentPool rank',
				size: 200,
				Cell: ({ cell }) => (
					<Box
						component="span"
						sx={(theme) => ({
							backgroundColor:
								cell.getValue() >= 3
									? '#CD7F32'
									: cell.getValue() <= 2 &&
										  cell.getValue() > 1
										? '#BEC2CB'
										: '#FFD700',
							borderRadius: '0.25rem',
							color: '#FFF',
							maxWidth: '9ch',
							p: '0.25rem',
						})}
					>
						{'taso ' + cell.getValue()}
					</Box>
				),
			},
			{
				accessorKey: 'user.email',
				header: 'Email',
			},
			{
				accessorKey: 'stage',
				header: 'Opintovaihe',
			},
			{
				accessorKey: 'category',
				header: 'Kategoria',
			},
			{
				accessorKey: 'subCategory',
				header: 'Alakategoria',
			},
			{
				accessorKey: 'city',
				header: 'Kaupunki',
			},
		],
		[isCompany],
	);

	return (
		<MaterialReactTable
			columns={columns}
			data={data ? data.data.results : []}
			initialState={{ showColumnFilters: true }}
			muiTableBodyRowProps={({ row }) => ({
				onClick: () => {
					const currentTalent = data.data.results.find(
						(talent) => talent.id === row.original.id,
					);
					navigate(`${currentTalent.id}`);
				},
				sx: {
					cursor: 'pointer',
				},
			})}
		/>
	);
};

export default TalentsTable;
