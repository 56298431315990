import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import {
	AppBar,
	Avatar,
	Button,
	Divider,
	Menu,
	MenuItem,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from '../logo.png';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/User/userSlice';
import Sidebar from './Sidebar';
import UserMenu from './UserMenu';

const drawerWidth = 280;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		flexGrow: 1,
		marginTop: '104px',

		...(!open && {
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			[theme.breakpoints.up('md')]: {
				marginLeft: -(drawerWidth - 20),
				width: `calc(100% - ${drawerWidth}px)`,
			},
			[theme.breakpoints.down('md')]: {
				marginLeft: '0px',
				width: `calc(100% - ${drawerWidth}px)`,
				padding: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: '1px',
				width: `calc(100% - ${drawerWidth}px)`,
				padding: '0px',
				marginRight: '0px',
			},
		}),
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: '20px',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			width: `calc(100% - ${drawerWidth}px)`,
			[theme.breakpoints.down('md')]: {
				marginLeft: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: '0px',
			},
		}),
	}),
);

export default function Layout() {
	const theme = useTheme();
	const navigate = useNavigate();
	const user = useSelector(selectUser);
	const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
	const [leftDrawerOpened, setLeftDrawerOpened] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleLeftDrawerToggle = () => {
		setLeftDrawerOpened(!leftDrawerOpened);
	};

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				backgroundColor: theme.palette.grey[100],
				height: '100%',
			}}
		>
			<CssBaseline />
			<Box
				sx={{
					position: 'fixed',
					top: theme.spacing(2),
					left: theme.spacing(2),
					display: 'flex',
					alignItems: 'center',
					height: '74px',
					paddingLeft: theme.spacing(2),
					width: leftDrawerOpened ? drawerWidth : 60,
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					zIndex: 1100,
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Box
						component="img"
						sx={{ height: 73, cursor: 'pointer' }}
						alt="logo"
						src={logo}
						onClick={() => navigate('/')}
					/>
					{leftDrawerOpened && !matchDownMd && (
						<Typography
							variant="h6"
							noWrap
							sx={{ ml: 2, color: theme.palette.primary.main }}
						>
							Lenz Platform
						</Typography>
					)}
				</Box>
			</Box>
			<AppBar
				enableColorOnDark
				position="fixed"
				elevation={3}
				sx={{
					transition: theme.transitions.create(
						['margin-left', 'width'],
						{
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.leavingScreen,
						},
					),
					marginLeft: leftDrawerOpened
						? `${drawerWidth + 20}px`
						: matchDownMd
							? '80px'
							: '80px',
					width: leftDrawerOpened
						? matchDownMd
							? `calc(100% - 150px)`
							: `calc(100% - ${drawerWidth + 40}px)`
						: matchDownMd
							? `calc(100% - 150px)`
							: `calc(100% - 150px)`,
					borderRadius: '12px',
					backgroundColor: theme.palette.background.paper,
					boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
				}}
			>
				<Toolbar sx={{ justifyContent: 'space-between' }}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={handleLeftDrawerToggle}
						sx={{ marginRight: 2 }}
					>
						<MenuIcon />
					</IconButton>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Button
							variant="outlined"
							color="primary"
							sx={{
								marginRight: 0,
								display: 'flex',
								alignItems: 'center',
								background:
									'linear-gradient(45deg, #7c55e5, #fa7921)',
								WebkitBackgroundClip: 'text',
								WebkitTextFillColor: 'transparent',
							}}
							onClick={() => navigate('/talent-chat')}
						>
							<AutoAwesomeIcon
								sx={{
									mr: 1,
									color: 'linear-gradient(45deg, #7c55e5, #fa7921)',
									'@media (max-width: 600px)': {
										fontSize: '1rem',
									},
								}}
							/>
							<span>Matchaa</span>
						</Button>

						<IconButton onClick={handleMenuClick}>
							<Avatar alt={user?.name} src={user?.avatar} />
						</IconButton>
						<UserMenu
							anchorEl={anchorEl}
							handleMenuClose={handleMenuClose}
						/>
					</Box>
				</Toolbar>
			</AppBar>

			<Sidebar
				drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
				drawerToggle={handleLeftDrawerToggle}
			/>
			<Main
				theme={theme}
				open={leftDrawerOpened}
				sx={{
					backgroundColor: theme.palette.grey[100],
					overflow: 'auto',
				}}
			>
				<Container maxWidth={false} sx={{ mt: 0, mb: 4, Left: 4 }}>
					<Outlet />
				</Container>
			</Main>
		</Box>
	);
}
