import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
	{
		avatar: (
			<Avatar alt="Tuomas Iivanainen" src="/static/images/avatar/1.jpg" />
		),
		name: 'Tuomas Iivanainen',
		occupation: 'Director International Business, Talenom Oyj',
		testimonial:
			'Yhteistyö Lenzin kanssa ylitti odotukseni. Opiskelijat yllättivät, kuinka nopeasti he oppivat, ja miten hyvin he pystyivät toimimaan tiiminä. He suoriutuivat yhtä hyvin – ja jossain asioissa jopa paremmin – kuin kokeneemmat työntekijät. Ohjaus ja viestintä onnistui myös hienosti, eikä minun tarvinnut nähdä ylimääräistä vaivaa projektin aikana. Voin lämpimästi suositella Lenziä myös muille.',
	},
	{
		avatar: (
			<Avatar alt="Jonne Honkanen" src="/static/images/avatar/2.jpg" />
		),
		name: 'Jonne Honkanen',
		occupation: 'Vice President & GM, Wihuri Oy Tekninen Kauppa',
		testimonial:
			'Te olette miettineet tätä kokonaisuutena ja oikeasti yrityksen näkökulmasta, ei ainoastaan teknisestä, vaan myös bisneksen, markkinoinnin, organisoitumisen ja muutosjohtamisen näkökulmasta. Saimme enemmän, kuin alussa odotin.',
	},
	{
		avatar: (
			<Avatar alt="Sami Juntunen" src="/static/images/avatar/6.jpg" />
		),
		name: 'Sami Juntunen',
		occupation: 'Tuotantojohtaja, Iisveden Metsä Oy',
		testimonial:
			'Lenz toimi läpi projektin innovatiivisesti. Tarpeemme täsmentyivät ja muuttuivat projektin aikana ja Lenz otti tämän erinomaisesti huomioon. Kokonaisuus pysyi hyvin hallinnassa läpi koko projektin ja olemme todella tyytyväisiä Lenzin aikaansaamaan lopputulokseen.',
	},

	{
		avatar: (
			<Avatar alt="Jaana Mäntymaa" src="/static/images/avatar/4.jpg" />
		),
		name: 'Jaana Mäntymaa',
		occupation: 'HR & ESG Director, Alltime',
		testimonial:
			'Yhteistyö on ollut oikein ketterää ja siitä puuttuu turha jäykkyys. Lenzin kanssa saadaan aina nopeasti sovittua kaikki asiat ja tarpeeton byrokratia on riisuttu pois.',
	},
	{
		avatar: (
			<Avatar alt="Stefan Wollsten" src="/static/images/avatar/5.jpg" />
		),
		name: 'Stefan Wollsten',
		occupation: 'Head of PMO, Havator',
		testimonial:
			'Opiskelijat olivat hyvin proaktiivisia ja nälkäisiä oppimaan. He olivat myös avoimia ja palvelualttiita. Suosittelisin Lenzin palvelua kaikille, joilla on tarvetta väliaikaiseen tukiorganisaatioon.',
	},
	{
		avatar: (
			<Avatar alt="Fredrik Grahn" src="/static/images/avatar/3.jpg" />
		),
		name: 'Fredrik Grahn',
		occupation: 'Senior Marketing Manager, Wihuri Metro-tukku',
		testimonial:
			'Olimme todella tyytyväisiä projektin lopputuloksiin. Projektin onnistumisen avaimena oli loistava ja omatoiminen tiimi.',
	},
];

const talentTestimonials = [
	{
		avatar: (
			<Avatar
				sx={{ width: 70, height: 70 }}
				alt="Elias"
				src="https://lenz.fi/wp-content/uploads/2024/09/IMG_2034.jpg"
			/>
		),
		name: 'Elias Kerttula',
		occupation: 'Tietoturvakoordinaattori',
		testimonial:
			'Tosi siistiä päästä syventymään tietoturva-aiheisiin yrityksen toiminnan tasolla. Lenz on ollut heti projektin alkupäässä todella kannustava ja positiivinen.',
	},
	{
		avatar: (
			<Avatar
				sx={{ width: 70, height: 70 }}
				alt="Julia Palola"
				src="https://lenz.fi/wp-content/uploads/2023/09/meika2-200x200.jpg"
			/>
		),
		name: 'Julia Palola',
		occupation: 'Visuaalinen asiantuntija',
		testimonial:
			'Pääsin projektissa graafisen suunnittelutyön lisäksi toteuttamaan ensimmäistä kertaa laajempaa videoproduktiota käsikirjoituksesta lopputuotantoon. Projekti opetti minulle älyttömästi ja en voi tarpeeksi kiittää luottamuksesta Lenzin kokeneempia konsultteja sekä valtavan yhteistyöhaluista asiakasta. Oli kunnia olla mukana tekemässä jotakin merkityksellistä!',
	},
	{
		avatar: (
			<Avatar
				sx={{ width: 70, height: 70 }}
				alt="Susanna"
				src="https://lenz.fi/wp-content/uploads/2024/09/omakuva4-2.jpg"
			/>
		),
		name: 'Susanna Halonen',
		occupation: 'Tietoturvakoordinaattori',
		testimonial:
			'Fiilikset projektin aloituksesta on huikeat! Uudet asiat, joita projektin aikana pääsee oppimaan tuntuvat innostavilta ja uudet haasteet motivoivat.',
	},
];

const whiteLogos = [
	'https://lenz.fi/wp-content/uploads/2021/10/talenom-logo.png',
	'https://www.wihuri.fi/wp-content/uploads/2023/04/Wihuri-4v-vaaka.png',
	'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
	'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
	'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
	'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
	'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
	'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
	'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
	'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
	'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
	'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
	height: '40px',
	width: '90px',
	opacity: 0.3,
};

export default function Testimonials({ userRole }) {
	const testimonials =
		userRole === 'student' ? talentTestimonials : userTestimonials;
	const theme = useTheme();
	const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

	return (
		<Container
			id="testimonials"
			sx={{
				pt: { xs: 4, sm: 12 },
				pb: { xs: 8, sm: 16 },
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: { xs: 3, sm: 6 },
			}}
		>
			<Box
				sx={{
					width: { sm: '100%', md: '60%' },
					textAlign: { sm: 'left', md: 'center' },
				}}
			>
				<Typography
					component="h2"
					variant="h4"
					sx={{ color: 'text.primary' }}
				>
					Kokemukset
				</Typography>
				<Typography variant="body1" sx={{ color: 'text.secondary' }}>
					Olemme auttaneet sekä opiskelijoita että yrityksiä
					saavuttamaan tavoitteensa ja luomaan merkityksellisiä
					projekteja, joissa molemmat osapuolet kehittyvät ja
					hyötyvät. Alla olevat esimerkit havainnollistavat, kuinka
					yritykset ovat löytäneet tarvitsemaansa osaamista, ja
					opiskelijat ovat päässeet kehittämään taitojaan aidoissa
					työelämän haasteissa.{' '}
				</Typography>
			</Box>
			<Grid container spacing={2}>
				{testimonials.map((testimonial, index) => (
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						key={index}
						sx={{ display: 'flex' }}
					>
						<Card
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								flexGrow: 1,
								p: 1,
							}}
						>
							<CardContent>
								<Typography
									variant="body2"
									sx={{ color: 'text.secondary' }}
								>
									{testimonial.testimonial}
								</Typography>
							</CardContent>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
									pr: 2,
								}}
							>
								<CardHeader
									avatar={testimonial.avatar}
									title={testimonial.name}
									subheader={testimonial.occupation}
								/>
								{/* <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                /> */}
							</Box>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}
