import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
	Box,
	Typography,
	TextField,
	Button,
	useTheme,
	useMediaQuery,
	Tabs,
	Tab,
	CircularProgress,
	ThemeProvider,
	createTheme,
	CssBaseline,
	IconButton,
	Grid,
	Paper,
	Alert,
	MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	ResponsiveContainer,
} from 'recharts';
import { openaiService } from '../../../services/openaiService';
import * as talentService from '../../../services/talentService';
import TalentCard from './TalentCard';
import logo from '../../../logo.png';
import getLPTheme from '../getLPTheme';
import { selectUser, clearUser } from '../../../features/User/userSlice';
import { keyframes } from '@mui/system';
import emailjs from '@emailjs/browser';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import WorkIcon from '@mui/icons-material/Work';
import PaymentsIcon from '@mui/icons-material/Payments';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import InfoIcon from '@mui/icons-material/Info';

const AppContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	height: '100vh',
	overflow: 'hidden',
	backgroundColor: theme.palette.background.default,
}));

const Header = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	backgroundColor: theme.palette.background.paper,
	boxShadow: theme.shadows[1],
	zIndex: 1,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}));

const MainContent = styled(Box)({
	flexGrow: 1,
	display: 'flex',
	overflow: 'hidden',
});

const ChatContainer = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	display: 'flex',
	flexDirection: 'column',
	borderRight: `1px solid ${theme.palette.divider}`,
	width: '60%',
}));

const ChatMessages = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	overflowY: 'auto',
	padding: theme.spacing(2),
}));

const ChatMessage = styled(Box)(({ theme, role }) => ({
	padding: theme.spacing(1, 2),
	marginBottom: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
	maxWidth: '70%',
	alignSelf: role === 'user' ? 'flex-end' : 'flex-start',
	backgroundColor:
		role === 'user'
			? theme.palette.primary.main
			: theme.palette.background.paper,
	color:
		role === 'user'
			? theme.palette.primary.contrastText
			: theme.palette.text.primary,
	boxShadow: theme.shadows[1],
}));

const ChatInput = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: theme.spacing(2),
	backgroundColor: theme.palette.background.paper,
	borderTop: `1px solid ${theme.palette.divider}`,
}));

const ResultsPanel = styled(Box)(({ theme }) => ({
	width: '40%',
	overflowY: 'auto',
	padding: theme.spacing(2),
	[theme.breakpoints.down('md')]: {
		width: '100%',
	},
}));

const MobileNavigation = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-around',
	padding: theme.spacing(1),
	backgroundColor: theme.palette.background.paper,
	borderTop: `1px solid ${theme.palette.divider}`,
	position: 'sticky',
	bottom: 0,
	zIndex: 1,
}));

// ProjectCard-komponentin määrittely tässä tiedostossa
const ProjectCard = ({ team, onRemoveMember, projectDuration }) => {
	const theme = useTheme();

	const calculateTeamRoles = () => {
		const roles = {
			coordinator: 0,
			challenger: 0,
			visionary: 0,
			analyst: 0,
			implementer: 0,
			teamPlayer: 0,
			researcher: 0,
			finisher: 0,
			specialist: 0,
		};

		team.forEach((member) => {
			Object.keys(roles).forEach((role) => {
				roles[role] += member.teamrole[role] || 0;
			});
		});

		return Object.keys(roles).map((role) => ({
			subject: role.charAt(0).toUpperCase() + role.slice(1),
			A: roles[role] / team.length,
		}));
	};

	const calculateTeamCosts = () => {
		const totalHourlyRate = team.reduce(
			(sum, member) => sum + (member.hourlyRate || 0),
			0,
		);
		const estimatedMonthlyRate = totalHourlyRate * 160;
		const totalProjectCost = estimatedMonthlyRate * projectDuration;
		return { totalHourlyRate, estimatedMonthlyRate, totalProjectCost };
	};

	const { totalHourlyRate, estimatedMonthlyRate, totalProjectCost } =
		calculateTeamCosts();

	return (
		<Box>
			<Typography variant="h6" gutterBottom>
				Tiimin jäsenet:
			</Typography>
			{team.map((member, index) => (
				<Box key={member.id} sx={{ mb: 2 }}>
					<Typography>
						{member.name} - {member.hourlyRate}€/h
					</Typography>
					<Button onClick={() => onRemoveMember(index)} size="small">
						Poista
					</Button>
				</Box>
			))}
			<Typography>Tiimin kokonaishinta: {totalHourlyRate}€/h</Typography>
			<Typography>
				Arvioitu kuukausihinta: {estimatedMonthlyRate}€/kk
			</Typography>
			<Typography>
				Projektin kokonaishinta: {totalProjectCost}€
			</Typography>
			<Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
				Tiimin roolijakauma:
			</Typography>
			<Box height={200}>
				<ResponsiveContainer width="100%" height="100%">
					<RadarChart
						cx="50%"
						cy="50%"
						outerRadius="80%"
						data={calculateTeamRoles()}
					>
						<PolarGrid />
						<PolarAngleAxis dataKey="subject" />
						<Radar
							name="Tiimi"
							dataKey="A"
							stroke={theme.palette.primary.main}
							fill={theme.palette.primary.main}
							fillOpacity={0.6}
						/>
					</RadarChart>
				</ResponsiveContainer>
			</Box>
		</Box>
	);
};

// Lisää pyörimisanimaatio
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Luo uusi styled-komponentti pyörivälle logolle
const SpinningLogo = styled('img')(({ theme }) => ({
	animation: `${spin} 2s linear infinite`,
	width: 30,
	height: 35,
}));

const TalentChatView = () => {
	const [chatMessages, setChatMessages] = useState([]);
	const [userInput, setUserInput] = useState('');
	const [loading, setLoading] = useState(false);
	const [talentResults, setTalentResults] = useState([]);
	const [team, setTeam] = useState([]);
	const [activeTab, setActiveTab] = useState(0);
	const [projectDuration, setProjectDuration] = useState(3);
	const [searchStatus, setSearchStatus] = useState('idle');
	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isSearching, setIsSearching] = useState(false);
	const [searchTimer, setSearchTimer] = useState(null);
	const [messageCount, setMessageCount] = useState(0);

	// Käytetään getLPTheme-funktiota teeman luomiseen
	const userRole = user ? user.role : 'student'; // Oletetaan, että käyttäjällä on rooli
	const lpTheme = React.useMemo(
		() => createTheme(getLPTheme(userRole)),
		[userRole],
	);

	const isMobile = useMediaQuery(lpTheme.breakpoints.down('md'));
	const chatEndRef = useRef(null);

	useEffect(() => {
		chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	}, [chatMessages]);

	const handleUserInput = (e) => {
		setUserInput(e.target.value);
	};

	const sendMessage = async () => {
		if (!userInput.trim()) return;

		const newUserMessage = { role: 'user', content: userInput };
		setChatMessages([...chatMessages, newUserMessage]);
		setUserInput('');
		setLoading(true);
		setMessageCount((prevCount) => prevCount + 1);

		try {
			const chatStream = await openaiService.getChatStream([
				...chatMessages,
				newUserMessage,
			]);
			let fullResponse = '';
			let newAssistantMessage = { role: 'assistant', content: '' };

			for await (const chunk of chatStream) {
				fullResponse += chunk;
				newAssistantMessage.content = fullResponse;
				setChatMessages([
					...chatMessages,
					newUserMessage,
					newAssistantMessage,
				]);
			}

			setLoading(false);

			// Tarkista, onko tarpeeksi viestejä ja sisältöä hakua varten
			if (messageCount >= 2 && fullResponse.length > 50 && !isSearching) {
				startSearch();
			}
		} catch (error) {
			console.error('Virhe AI-vastauksessa:', error);
			setChatMessages([
				...chatMessages,
				newUserMessage,
				{
					role: 'assistant',
					content:
						'Pahoittelen, tapahtui virhe. Voitko yrittää uudelleen?',
				},
			]);
			setLoading(false);
		}
	};

	const startSearch = () => {
		setIsSearching(true);
		if (searchTimer) clearTimeout(searchTimer);

		const timer = setTimeout(async () => {
			try {
				const talentJson =
					await openaiService.getTalentJson(chatMessages);
				if (talentJson) {
					const results = await talentService.hybridSearch(
						JSON.stringify(talentJson),
					);
					setTalentResults(results.slice(0, 3));
				}
			} catch (error) {
				console.error('Virhe talenttien haussa:', error);
			} finally {
				setIsSearching(false);
			}
		}, 5000); // 5 sekunnin viive ennen hakua

		setSearchTimer(timer);
	};

	const addToTeam = (talent) => {
		if (!team.some((member) => member.id === talent.id)) {
			setTeam([...team, talent]);
		}
	};

	const removeFromTeam = (index) => {
		setTeam(team.filter((_, i) => i !== index));
	};

	const createTeam = async () => {
		if (team.length === 0) return;

		try {
			const createdTeam = await talentService.createTeam({
				members: team,
			});
			console.log('Tiimi luotu:', createdTeam);
			// Tässä voit lisätä logiikkaa tiimin luomisen jälkeen, esim. näyttää ilmoituksen käyttäjälle
		} catch (error) {
			console.error('Virhe tiimin luomisessa:', error);
		}
	};

	const handleAuthAction = () => {
		if (user) {
			// Uloskirjautuminen
			dispatch(clearUser());
			// Mahdollinen uudelleenohjaus etusivulle tai kirjautumissivulle
			navigate('/');
		} else {
			// Ohjaa kirjautumissivulle
			navigate('/login');
		}
	};

	return (
		<ThemeProvider theme={lpTheme}>
			<CssBaseline />
			<AppContainer>
				<Header>
					<Typography
						variant={isMobile ? 'h5' : 'h4'}
						display="flex"
						alignItems="center"
					>
						Talent Chat
						<img
							src={logo}
							alt="Lenz logo"
							style={{
								width: isMobile ? 30 : 40,
								height: isMobile ? 35 : 45,
								marginLeft: 12,
							}}
						/>
					</Typography>

					<Button
						variant="outlined"
						startIcon={<AccountCircleIcon />}
						onClick={handleAuthAction}
						sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}
					>
						{user ? `Kirjaudu ulos` : 'Kirjaudu sisään'}
					</Button>
				</Header>
				<MainContent>
					{(!isMobile || (isMobile && activeTab === 0)) && (
						<ChatContainer>
							<ChatMessages>
								{chatMessages.map((message, index) => (
									<ChatMessage
										key={index}
										role={message.role}
									>
										<Typography
											sx={{ whiteSpace: 'pre-wrap' }}
										>
											{message.content}
										</Typography>
									</ChatMessage>
								))}
								<div ref={chatEndRef} />
							</ChatMessages>
							<ChatInput>
								<TextField
									fullWidth
									variant="outlined"
									value={userInput}
									onChange={handleUserInput}
									placeholder="Kerro tarpeistasi..."
									disabled={loading}
									autoComplete="off"
								/>
								<IconButton
									onClick={sendMessage}
									color="primary"
									disabled={loading}
									sx={{ ml: 1 }}
								>
									{loading ? (
										<SpinningLogo
											src={logo}
											alt="Lenz logo"
										/>
									) : (
										<SendIcon />
									)}
								</IconButton>
							</ChatInput>
						</ChatContainer>
					)}
					{(!isMobile ||
						(isMobile && (activeTab === 1 || activeTab === 2))) && (
						<ResultsPanel>
							{!isMobile && (
								<Tabs
									value={activeTab}
									onChange={(e, newValue) =>
										setActiveTab(newValue)
									}
									variant="fullWidth"
								>
									<Tab label="Tulokset" />
									<Tab label="Projekti" />
								</Tabs>
							)}
							{((!isMobile && activeTab === 0) ||
								(isMobile && activeTab === 1)) && (
								<TalentResults
									talentResults={talentResults}
									addToTeam={addToTeam}
									team={team}
									isSearching={isSearching}
									isAuthenticated={!!user}
								/>
							)}
							{((!isMobile && activeTab === 1) ||
								(isMobile && activeTab === 2)) && (
								<ProjectView
									team={team}
									removeFromTeam={removeFromTeam}
									projectDuration={projectDuration}
									setProjectDuration={setProjectDuration}
									chatMessages={chatMessages}
									talentResults={talentResults}
									isAuthenticated={!!user}
								/>
							)}
						</ResultsPanel>
					)}
				</MainContent>
				{isMobile && (
					<MobileNavigation>
						<Button
							onClick={() => setActiveTab(0)}
							color={activeTab === 0 ? 'primary' : 'inherit'}
						>
							Chat
						</Button>
						<Button
							onClick={() => setActiveTab(1)}
							color={activeTab === 1 ? 'primary' : 'inherit'}
						>
							Tulokset
						</Button>
						<Button
							onClick={() => setActiveTab(2)}
							color={activeTab === 2 ? 'primary' : 'inherit'}
						>
							Projekti
						</Button>
					</MobileNavigation>
				)}
			</AppContainer>
		</ThemeProvider>
	);
};

const TalentResults = ({
	talentResults,
	addToTeam,
	team,
	isSearching,
	isAuthenticated,
}) => (
	<Box>
		<Typography variant="h6" gutterBottom>
			Ehdotetut talentit:
		</Typography>
		{isSearching ? (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={100}
			>
				<SpinningLogo src={logo} alt="Lenz logo" />
			</Box>
		) : talentResults.length > 0 ? (
			<Grid container spacing={2}>
				{talentResults.map(({ talent, vs_score }, index) => (
					<Grid item xs={12} key={talent.id}>
						<TalentCard
							talent={talent}
							vs_score={vs_score}
							onAddToTeam={() => addToTeam(talent)}
							isInTeam={team.some(
								(member) => member.id === talent.id,
							)}
							index={index}
							isAuthenticated={isAuthenticated}
						/>
					</Grid>
				))}
			</Grid>
		) : (
			<Typography>
				Ei vielä löydettyjä talentteja. Jatka keskustelua saadaksesi
				ehdotuksia.
			</Typography>
		)}
	</Box>
);

const ProjectView = ({
	team,
	removeFromTeam,
	projectDuration,
	setProjectDuration,
	chatMessages,
	talentResults,
	isAuthenticated,
}) => {
	const [orderStatus, setOrderStatus] = useState('idle');
	const [projectDetails, setProjectDetails] = useState({
		clientCompany: '',
		contactPerson: '',
		contactEmail: '',
		additionalInfo: '',
	});
	const theme = useTheme();
	const [monthlyHours, setMonthlyHours] = useState(160);

	// Alennusprosentti projektin keston mukaan
	const calculateDiscount = (months) => {
		if (months >= 12) return 15; // 12kk tai yli: 15% alennus
		if (months >= 6) return 10; // 6-11kk: 10% alennus
		if (months >= 3) return 5; // 3-5kk: 5% alennus
		return 0; // Alle 3kk: ei alennusta
	};

	const handleProjectDetailsChange = (field) => (event) => {
		setProjectDetails({
			...projectDetails,
			[field]: event.target.value,
		});
	};

	const calculateTeamCosts = () => {
		const totalHourlyRate = team.reduce((sum, member) => {
			const hourlyRate = isAuthenticated ? member.hourlyRate || 45 : 45;
			return sum + hourlyRate;
		}, 0);

		const discount = calculateDiscount(projectDuration);
		const discountMultiplier = (100 - discount) / 100;

		const estimatedMonthlyRate =
			totalHourlyRate * monthlyHours * discountMultiplier;
		const totalProjectCost = estimatedMonthlyRate * projectDuration;

		return {
			totalHourlyRate: Math.round(totalHourlyRate),
			estimatedMonthlyRate: Math.round(estimatedMonthlyRate),
			totalProjectCost: Math.round(totalProjectCost),
			discount,
		};
	};

	const {
		totalHourlyRate,
		estimatedMonthlyRate,
		totalProjectCost,
		discount,
	} = calculateTeamCosts();

	const handleOrderProject = async () => {
		setOrderStatus('loading');
		// Simuloidaan lähetystä pienellä viiveellä
		setTimeout(() => {
			setOrderStatus('success');
		}, 1500);
	};

	return (
		<Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
			<Typography
				variant="h5"
				gutterBottom
				color="primary"
				sx={{ mb: 4 }}
			>
				Projektin yhteenveto
			</Typography>

			{/* Tiimin kokoonpano */}
			<Paper
				elevation={0}
				sx={{ p: 3, mb: 4, backgroundColor: theme.palette.grey[50] }}
			>
				<Typography
					variant="h6"
					sx={{ display: 'flex', alignItems: 'center', mb: 3 }}
				>
					<GroupIcon sx={{ mr: 1 }} />
					Tiimin kokoonpano ({team.length} jäsentä)
				</Typography>

				{team.length > 0 ? (
					<Box sx={{ mb: 2 }}>
						{team.map((member, index) => (
							<Paper
								key={member.id}
								sx={{
									p: 2,
									mb: 2,
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Box>
									<Typography variant="subtitle1">
										Talentti {index + 1}
									</Typography>
									<Typography
										variant="body2"
										color="text.secondary"
									>
										{member.university}
									</Typography>
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 2,
									}}
								>
									<Typography
										variant="subtitle2"
										color="primary"
									>
										{isAuthenticated
											? member.hourlyRate || 45
											: 45}
										€/h
										{!isAuthenticated && (
											<Typography
												component="span"
												variant="caption"
												sx={{ ml: 1, opacity: 0.7 }}
											>
												(arvio)
											</Typography>
										)}
									</Typography>
									<IconButton
										size="small"
										onClick={() => removeFromTeam(index)}
										sx={{ color: theme.palette.error.main }}
									>
										<DeleteIcon />
									</IconButton>
								</Box>
							</Paper>
						))}
					</Box>
				) : (
					<Alert severity="info">
						Ei vielä lisättyjä jäseniä. Lisää talentteja tiimiin
						Tulokset-välilehdeltä.
					</Alert>
				)}
			</Paper>

			{/* Kustannukset ja aikataulu */}
			<Paper
				elevation={0}
				sx={{ p: 3, mb: 4, backgroundColor: theme.palette.grey[50] }}
			>
				<Typography
					variant="h6"
					sx={{ display: 'flex', alignItems: 'center', mb: 3 }}
				>
					<PaymentsIcon sx={{ mr: 1 }} />
					Kustannukset ja aikataulu
				</Typography>

				{!isAuthenticated && (
					<Alert severity="info" sx={{ mb: 3 }}>
						Kirjautumalla näet tarkat hintatiedot. Alla esitetyt
						hinnat ovat arvioita perustuen 45€/h keskituntihintaan.
					</Alert>
				)}

				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							type="number"
							label="Projektin kesto (kuukausia)"
							value={projectDuration}
							onChange={(e) =>
								setProjectDuration(Number(e.target.value))
							}
							InputProps={{ inputProps: { min: 1 } }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							type="number"
							label="Työtunteja kuukaudessa"
							value={monthlyHours}
							onChange={(e) =>
								setMonthlyHours(Number(e.target.value))
							}
							InputProps={{
								inputProps: { min: 20, max: 160 },
								endAdornment: (
									<Typography sx={{ ml: 1 }}>h/kk</Typography>
								),
							}}
							helperText="Suositus: 80-160h/kk per henkilö"
						/>
					</Grid>
				</Grid>

				<Box
					sx={{
						mt: 3,
						p: 2,
						backgroundColor: 'white',
						borderRadius: 1,
					}}
				>
					<Typography variant="subtitle2" gutterBottom>
						{isAuthenticated
							? 'Kustannusarvio:'
							: 'Alustava kustannusarvio:'}
					</Typography>
					<Box sx={{ pl: 2 }}>
						<Typography variant="body2" sx={{ mb: 1 }}>
							Tiimin tuntihinta: {totalHourlyRate}€/h
							{!isAuthenticated && ' (arvio)'}
						</Typography>
						<Typography variant="body2" sx={{ mb: 1 }}>
							Työtunteja yhteensä:{' '}
							{monthlyHours * projectDuration}h
						</Typography>
						<Typography variant="body2" sx={{ mb: 1 }}>
							Kuukausihinta: {estimatedMonthlyRate}€/kk
							{!isAuthenticated && ' (arvio)'}
						</Typography>
						{discount > 0 && (
							<Box
								sx={{
									backgroundColor:
										theme.palette.success.light,
									color: 'white',
									p: 2,
									borderRadius: 1,
									mt: 2,
									mb: 2,
								}}
							>
								<Typography variant="subtitle1">
									Projektin keston alennus: -{discount}%
								</Typography>
								<Typography variant="body2">
									Pitkäkestoisissa projekteissa myönnämme
									automaattisen alennuksen:
								</Typography>
								<Typography
									variant="body2"
									component="ul"
									sx={{ mt: 1 }}
								>
									<li>3-5kk projekti: 5% alennus</li>
									<li>6-11kk projekti: 10% alennus</li>
									<li>12kk tai pidempi: 15% alennus</li>
								</Typography>
							</Box>
						)}
						<Typography variant="h6" color="primary" sx={{ mt: 2 }}>
							Projektin kokonaishinta: {totalProjectCost}€
							{!isAuthenticated && ' (arvio)'}
						</Typography>
					</Box>
				</Box>
			</Paper>

			{/* Yhteystiedot */}
			<Paper
				elevation={0}
				sx={{ p: 3, mb: 4, backgroundColor: theme.palette.grey[50] }}
			>
				<Typography
					variant="h6"
					sx={{ display: 'flex', alignItems: 'center', mb: 3 }}
				>
					<ContactMailIcon sx={{ mr: 1 }} />
					Yhteystiedot
				</Typography>

				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="Yritys"
							value={projectDetails.clientCompany}
							onChange={handleProjectDetailsChange(
								'clientCompany',
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="Yhteyshenkilö"
							value={projectDetails.contactPerson}
							onChange={handleProjectDetailsChange(
								'contactPerson',
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Sähköposti"
							type="email"
							value={projectDetails.contactEmail}
							onChange={handleProjectDetailsChange(
								'contactEmail',
							)}
						/>
					</Grid>
				</Grid>
			</Paper>

			{/* Lisätiedot */}
			<Paper
				elevation={0}
				sx={{ p: 3, mb: 4, backgroundColor: theme.palette.grey[50] }}
			>
				<Typography
					variant="h6"
					sx={{ display: 'flex', alignItems: 'center', mb: 3 }}
				>
					<InfoIcon sx={{ mr: 1 }} />
					Lisätiedot
				</Typography>

				<TextField
					fullWidth
					multiline
					rows={4}
					label="Muut toiveet ja lisätiedot"
					value={projectDetails.additionalInfo}
					onChange={handleProjectDetailsChange('additionalInfo')}
				/>
			</Paper>

			{/* Toimintopainikkeet */}
			<Box sx={{ mt: 4 }}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleOrderProject}
					disabled={
						team.length === 0 ||
						orderStatus === 'loading' ||
						orderStatus === 'success'
					}
					fullWidth
					size="large"
					sx={{ py: 2, borderRadius: 2 }}
					startIcon={<SendIcon />}
				>
					{orderStatus === 'loading'
						? 'Lähetetään...'
						: 'Lähetä tarjouspyyntö'}
				</Button>
			</Box>

			{orderStatus === 'success' && (
				<Alert severity="success" sx={{ mt: 2 }}>
					Tarjouspyyntösi on vastaanotettu! Myyjämme ottaa sinuun pian
					yhteyttä.
				</Alert>
			)}
		</Box>
	);
};

export default TalentChatView;
