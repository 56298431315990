// talentDTO.js
export const updateTalentDto = (data) => {
	const {
		city,
		university,
		stage,
		major,
		minor,
		phone,
		motto,
		favoriteColor,
		category,
	} = data;
	return {
		city,
		university,
		stage,
		major,
		minor,
		phone,
		motto,
		favoriteColor,
		category,
	};
};
