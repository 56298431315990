import { useQuery } from 'react-query';
import { recruitService } from '../../../services';

export const useRecruit = () => {
	const now = new Date();

	const {
		data: recruits,
		isLoading,
		error,
	} = useQuery('recruits', recruitService.getRecruits);

	const openRecruits = recruits?.data.results.filter(
		(recruit) =>
			new Date(recruit.endDate) >= now &&
			new Date(recruit.startDate) <= now,
	);
	const pastRecruits = recruits?.data.results.filter(
		(recruit) => new Date(recruit.endDate) < now,
	);
	const futureRecruits = recruits?.data.results.filter(
		(recruit) => new Date(recruit.startDate) > now,
	);

	return {
		recruits,
		isLoading,
		error,
		openRecruits,
		pastRecruits,
		futureRecruits,
	};
};
