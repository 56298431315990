import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import TalentsTable from './TalentsTable';
import TalentsBrowser from './TalentsBrowser';

const Talents = () => {
	const [isTableView, setIsTableView] = useState(false);

	const handleViewChange = () => {
		setIsTableView(!isTableView);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Typography variant="h4">TalentPool</Typography>
					<FormControlLabel
						control={
							<Switch
								checked={isTableView}
								onChange={handleViewChange}
							/>
						}
						label={isTableView ? 'Table View' : 'Browser View'}
					/>
				</Box>
				{isTableView ? <TalentsTable /> : <TalentsBrowser />}
			</Grid>
		</Grid>
	);
};

export default Talents;
