import React, { useEffect, useState } from 'react';
import {
	Grid,
	Paper,
	Typography,
	IconButton,
	Button,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Rating,
	Box,
	AccordionSummary,
	AccordionDetails,
	Accordion,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import {
	recruitService,
	applicationService,
	talentService,
} from '../../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Widget } from '@typeform/embed-react';
import StagesTable from 'features/Recruit/components/StagesTable';
import RecruitDialog from 'features/Recruit/components/RecruitDialog';
import { selectUser } from 'features/User/userSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const RecruitPage = () => {
	const { recruitId } = useParams();
	const [recruit, setRecruit] = useState(null);
	const user = useSelector(selectUser);

	const navigate = useNavigate();

	const [openRecruitDialog, setOpenDialog] = useState(false);

	const handleClickRecruitOpen = () => {
		setOpenDialog(true);
	};

	const handleCloseRecruitDialog = () => {
		setOpenDialog(false);
	};

	const queryClient = useQueryClient();

	const vectorSearchMutation = useMutation(
		() => talentService.hybridSearch(recruit.description),
		{
			onSuccess: (data) => {
				queryClient.setQueryData('talentSuggestions', data);
			},
		},
	);

	const handleSearchClick = () => {
		if (recruit) {
			vectorSearchMutation.mutate();
		}
	};

	const {
		data: rData,
		error: recruitsError,
		isLoading: isLoadingRecruits,
	} = useQuery(['recruit', recruitId], () =>
		recruitService.getRecruit(recruitId),
	);
	const { data: talentSuggestions } = useQuery(
		'talentSuggestions',
		() => [],
		{
			initialData: [],
		},
	);

	const {
		data: aData,
		error: applicationError,
		isLoading: isLoadingApplications,
	} = useQuery(
		'applications',
		() => applicationService.getApplicationsByRecruitmentId(recruitId),
		{ enabled: !!recruit && user?.role === 'admin' },
	);

	const recruitsData = rData?.data;

	const applications = aData?.data;

	useEffect(() => {
		setRecruit(recruitsData);
		console.log('recruit: ', recruit);
	}, [rData]);

	if (isLoadingRecruits) {
		return 'Loading...';
	}

	if (recruitsError) {
		return 'An error has occurred: ' + recruitsError.message;
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				{recruit ? (
					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
					>
						{user?.role === 'talent' ? (
							<Grid height="70vh">
								<Typography variant="h6">
									{recruit.name}
								</Typography>
								<br />
								<Typography style={{ whiteSpace: 'pre-wrap' }}>
									{recruit.description}
								</Typography>
								<br />
								<Typography variant="h6">
									Hae tehtävään {recruit.name}
								</Typography>
								<Typography>
									Täytä tiimiroolitestin sekä muut tiedot{' '}
									<Link to="/">profiilissasi</Link> ennen
									tehtävään hakemista
								</Typography>
								<Widget
									id={recruit?.formId}
									hidden={{ user_id: user?.profileId }}
									style={{ width: '100%', height: '100%' }}
									className="my-form"
								/>
							</Grid>
						) : (
							<>
								{recruit && (
									<>
										<Grid
											container
											direction="row"
											justifyContent="center"
											alignItems="center"
										>
											<Grid item xs={12}>
												<Box
													display="flex"
													justifyContent="space-between"
													alignItems="center"
													width="100%"
												>
													<Typography
														variant="h6"
														style={{
															textAlign: 'left',
															width: '100%',
														}}
													>
														{recruit.name}
													</Typography>
													{recruit.formId && (
														<Button
															variant="outlined"
															component={Link}
															to={`https://admin.typeform.com/form/${recruit.formId}/create`}
														>
															Typeformiin
														</Button>
													)}
												</Box>
											</Grid>
											<Grid item xs={12}>
												<Accordion sx={{ mt: 2 }}>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon />
														}
													>
														Tehtävän kuvaus
													</AccordionSummary>
													<AccordionDetails>
														<Typography
															style={{
																whiteSpace:
																	'pre-wrap',
															}}
														>
															{
																recruit.description
															}
														</Typography>
													</AccordionDetails>
												</Accordion>
											</Grid>
										</Grid>
										<Paper
											sx={{
												p: 2,
												display: 'flex',
												flexDirection: 'column',
												mt: 2,
												mb: 2,
												width: '100%',
											}}
										>
											<Typography variant="h6">
												TalentPoolin ehdotukset
											</Typography>
											<Button onClick={handleSearchClick}>
												Hae TalentPoolin ehdotukset
											</Button>
											<TableContainer component={Paper}>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell>
																Name
															</TableCell>
															<TableCell>
																Major
															</TableCell>
															<TableCell>
																City
															</TableCell>
															<TableCell>
																Education
															</TableCell>
															<TableCell>
																Skills
															</TableCell>
															<TableCell align="right">
																Match
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{talentSuggestions.map(
															(talent) => (
																<TableRow
																	key={
																		talent._id
																	}
																	onClick={() =>
																		navigate(
																			`/app/talents/${talent._id}`,
																		)
																	}
																	hover={true}
																	sx={{
																		cursor: 'pointer',
																	}}
																>
																	<TableCell>
																		{' '}
																		{
																			talent
																				?.user
																				?.name
																		}
																	</TableCell>
																	<TableCell
																		component="th"
																		scope="row"
																	>
																		{
																			talent.major
																		}
																	</TableCell>
																	<TableCell>
																		{
																			talent.city
																		}
																	</TableCell>
																	<TableCell>
																		{
																			talent.education
																		}
																	</TableCell>
																	<TableCell>
																		{talent.skills.map(
																			(
																				skill,
																				index,
																			) => (
																				<div
																					key={
																						index
																					}
																				>
																					<Typography>
																						{
																							skill.skill
																						}

																						:{' '}
																					</Typography>
																					<Rating
																						name="read-only"
																						value={
																							skill.rating
																						}
																						readOnly
																					/>
																				</div>
																			),
																		)}
																	</TableCell>
																	<TableCell align="right">
																		{Math.round(
																			talent.score *
																				100,
																		) / 100}
																	</TableCell>
																</TableRow>
															),
														)}
													</TableBody>
												</Table>
											</TableContainer>
										</Paper>
									</>
								)}

								{recruit && applications && (
									<Paper
										sx={{
											p: 2,
											display: 'flex',
											flexDirection: 'column',
											mt: 2,
											mb: 2,
											width: '100%',
										}}
									>
										<Typography variant="h6">
											Rekrytoinnin vaiheet
											<IconButton
												onClick={handleClickRecruitOpen}
												aria-label="add"
											>
												<SettingsIcon
													style={{
														verticalAlign: 'middle',
													}}
												/>
											</IconButton>
											<RecruitDialog
												open={openRecruitDialog}
												handleClose={
													handleCloseRecruitDialog
												}
												recruit={recruit}
												onRecruitUpdated={(
													updatedRecruit,
												) => setRecruit(updatedRecruit)}
											/>
										</Typography>
										<StagesTable
											applications={applications}
											recruitment={recruit}
										/>
									</Paper>
								)}
							</>
						)}
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</Grid>
	);
};

export default RecruitPage;
