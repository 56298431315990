export const questions = {
	sections: [
		{
			title: 'Potentiaalitesti',
			questions: [
				// Kysymykset tähän
			],
		},
	],
};

export default questions;
