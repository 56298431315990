import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	IconButton,
	Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import { recruitService } from 'services';

const RecruitDialog = ({ open, handleClose, recruit, onRecruitUpdated }) => {
	const [updatedRecruit, setUpdatedRecruit] = useState(recruit);

	const handleInputChange = (event, index) => {
		const newStages = [...updatedRecruit.stages];
		newStages[index].id = event.target.value;
		setUpdatedRecruit({
			...updatedRecruit,
			stages: newStages,
		});
	};

	const handleAddStage = () => {
		setUpdatedRecruit({
			...updatedRecruit,
			stages: [...updatedRecruit.stages, { id: '', applicants: [] }],
		});
	};

	const handleRemoveStage = (indexToRemove) => {
		setUpdatedRecruit({
			...updatedRecruit,
			stages: updatedRecruit.stages.filter(
				(_, index) => index !== indexToRemove,
			),
		});
	};

	const handleSaveClick = () => {
		const stagesToSend = updatedRecruit.stages.map((stage) => ({
			_id: stage._id,
			id: stage.id,
		}));

		recruitService
			.updateRecruitmentStageNames(recruit.id, { stages: stagesToSend })
			.then((updatedRecruitFromServer) => {
				onRecruitUpdated(updatedRecruitFromServer);
			});
		handleClose();
	};

	const moveStageUp = (index) => {
		if (index === 0) return;
		const newStages = [...updatedRecruit.stages];
		[newStages[index - 1], newStages[index]] = [
			newStages[index],
			newStages[index - 1],
		];
		setUpdatedRecruit({
			...updatedRecruit,
			stages: newStages,
		});
	};

	const moveStageDown = (index) => {
		if (index === updatedRecruit.stages.length - 1) return;
		const newStages = [...updatedRecruit.stages];
		[newStages[index + 1], newStages[index]] = [
			newStages[index],
			newStages[index + 1],
		];
		setUpdatedRecruit({
			...updatedRecruit,
			stages: newStages,
		});
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Muokkaa rekrytoinnin vaiheita</DialogTitle>
			<DialogContent style={{ padding: '24px' }}>
				{updatedRecruit?.stages?.map((stage, index) => (
					<Box
						display="flex"
						alignItems="center"
						marginBottom={2}
						key={index}
					>
						<TextField
							name="id"
							label={`Vaihe ${index + 1}`}
							value={stage.id}
							onChange={(event) =>
								handleInputChange(event, index)
							}
							style={{ marginRight: 8, flexGrow: 1 }}
						/>
						<IconButton
							onClick={() => moveStageUp(index)}
							disabled={index === 0}
						>
							<ArrowUpwardIcon />
						</IconButton>
						<IconButton
							onClick={() => moveStageDown(index)}
							disabled={
								index === updatedRecruit.stages.length - 1
							}
						>
							<ArrowDownwardIcon />
						</IconButton>
						<IconButton onClick={() => handleRemoveStage(index)}>
							<DeleteIcon />
						</IconButton>
					</Box>
				))}
				<IconButton onClick={handleAddStage}>
					<AddIcon />
				</IconButton>
				<Button onClick={handleSaveClick} style={{ marginTop: '16px' }}>
					Tallenna
				</Button>
			</DialogContent>
		</Dialog>
	);
};

export default RecruitDialog;
