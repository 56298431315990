import axios from 'axios';
import store from '../store';
import { setError } from '../features/Notification/notificationSlice';

const baseUrl = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
	baseURL: `${baseUrl}/v1`,
});

let token = null;

const setToken = (newToken) => {
	token = `Bearer ${newToken}`;
};

const getRefreshToken = () => {
	const loggedUserJSON = window.localStorage.getItem('loggedUser');
	const loggedUser = JSON.parse(loggedUserJSON);
	return loggedUser?.tokens.refresh.token;
};

const refreshTokenPayload = {
	refreshToken: getRefreshToken(),
};

// Function to refresh the token
const refreshToken = async () => {
	const response = await axios.post(
		`${baseUrl}/v1/refresh-tokens`,
		refreshTokenPayload,
	);
	return response.data.newToken;
};

instance.interceptors.request.use((config) => {
	if (token) {
		config.headers.Authorization = token;
	}
	return config;
});

instance.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (
			error.response &&
			(error.response.status === 401 || error.response.status === 403) &&
			!error.config.__isRetryRequest
		) {
			try {
				const newToken = await refreshToken();
				setToken(newToken);
				error.config.__isRetryRequest = true;
				error.config.headers.Authorization = `Bearer ${newToken}`;
				return instance(error.config);
			} catch (refreshError) {
				if (error.config.url.includes('/widget')) {
					return Promise.reject(error);
				}
				window.location.href = '/login';
				return Promise.reject(refreshError);
			}
		}

		store.dispatch(
			setError(
				error.response ? error.response.data.message : 'Network Error',
			),
		);

		return Promise.reject(error);
	},
);

export default instance;
export { setToken };
