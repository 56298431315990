import React, { useState, useEffect } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import {
	Card,
	CardContent,
	Typography,
	Divider,
	TextField,
	CardActions,
	Button,
	Grid,
	Box,
} from '@mui/material';
import { talentService } from 'services';
import SchoolIcon from '@mui/icons-material/School';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const ProfileInfo = ({ talentId, talent, isTalent, color }) => {
	const queryClient = useQueryClient();
	const [editMode, setEditMode] = useState(false);
	const [updatedTalent, setUpdatedTalent] = useState(talent);

	useEffect(() => {
		setUpdatedTalent(talent);
	}, [talent]);

	const updateMutation = useMutation(
		(updatedData) => talentService.updateTalentById(talentId, updatedData),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['talentData', talentId]);
			},
		},
	);

	const handleInputChange = (event) => {
		setUpdatedTalent({
			...updatedTalent,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditClick = () => {
		setEditMode(true);
	};

	const handleSaveClick = () => {
		const updatedData = {
			description: updatedTalent.description,
		};
		updateMutation.mutate(updatedData);
		setEditMode(false);
	};

	const handleCancelClick = () => {
		setUpdatedTalent(talent);
		setEditMode(false);
	};

	const InfoItem = ({ icon, label, value }) => (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				mb: 2,
				'&:hover': {
					backgroundColor: 'rgba(0, 0, 0, 0.02)',
					borderRadius: 1,
				},
				p: 1,
			}}
		>
			{icon}
			<Box sx={{ ml: 2 }}>
				<Typography variant="caption" color="text.secondary">
					{label}
				</Typography>
				<Typography variant="body1">{value || '-'}</Typography>
			</Box>
		</Box>
	);

	return (
		<Card sx={{ overflow: 'hidden' }}>
			<CardContent onClick={!editMode ? handleEditClick : undefined}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<InfoItem
							icon={<SchoolIcon color="primary" />}
							label="Pääaine"
							value={talent?.major}
						/>
						<InfoItem
							icon={<MenuBookIcon color="primary" />}
							label="Sivuaine"
							value={talent?.minor}
						/>
						<InfoItem
							icon={<SchoolIcon color="primary" />}
							label="Oppilaitos"
							value={talent?.university}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<InfoItem
							icon={<LocationOnIcon color="primary" />}
							label="Kaupunki"
							value={talent?.city}
						/>
						<InfoItem
							icon={<EmailIcon color="primary" />}
							label="Sähköposti"
							value={talent?.user?.email}
						/>
						<InfoItem
							icon={<PhoneIcon color="primary" />}
							label="Puhelin"
							value={talent?.phone}
						/>
					</Grid>
				</Grid>

				<Divider variant="middle" sx={{ my: 3 }} />

				<Box sx={{ px: 1 }}>
					<Typography
						variant="h6"
						color="primary"
						sx={{
							display: 'flex',
							alignItems: 'center',
							mb: 2,
							fontWeight: 500,
						}}
					>
						Bio
						{!editMode && isTalent && (
							<Button
								variant="text"
								color="primary"
								onClick={handleEditClick}
								size="small"
								sx={{ ml: 2 }}
							>
								Muokkaa
							</Button>
						)}
					</Typography>

					{isTalent && !talent?.description ? (
						<Box
							sx={{
								backgroundColor: 'rgba(0, 0, 0, 0.02)',
								p: 3,
								borderRadius: 2,
								border: '1px dashed rgba(0, 0, 0, 0.1)',
							}}
						>
							<Typography
								variant="body1"
								color="primary"
								sx={{ mb: 1 }}
							>
								Kerro meille lisää itsestäsi – sana on vapaa! 💫
							</Typography>
							<Typography variant="body2" color="text.secondary">
								Voit kertoa esimerkiksi
								mielenkiinnonkohteistasi, osaamisestasi ja
								unelmistasi. Tämä on tilaisuutesi erottua ja
								kertoa, kuka juuri sinä olet!
							</Typography>
							<Typography
								variant="caption"
								color="text.secondary"
								sx={{ display: 'block', mt: 2 }}
							>
								Näiden tietojen avulla voimme tarjota sinulle
								parhaiten sopivia projekteja. Voit päivittää
								kuvausta milloin tahansa.
							</Typography>
						</Box>
					) : editMode ? (
						<TextField
							type="text"
							fullWidth
							variant="outlined"
							multiline
							rows={4}
							name="description"
							value={updatedTalent?.description}
							onChange={handleInputChange}
							placeholder="Kerro itsestäsi..."
							sx={{
								'& .MuiOutlinedInput-root': {
									backgroundColor: 'rgba(0, 0, 0, 0.02)',
								},
							}}
						/>
					) : (
						<Typography
							variant="body1"
							color="text.secondary"
							sx={{
								whiteSpace: 'pre-wrap',
								lineHeight: 1.6,
								p: 2,
								backgroundColor: 'rgba(0, 0, 0, 0.02)',
								borderRadius: 2,
								'&:hover': {
									backgroundColor: 'rgba(0, 0, 0, 0.03)',
								},
							}}
						>
							{talent?.description}
						</Typography>
					)}
				</Box>
			</CardContent>

			{editMode && (
				<CardActions
					sx={{
						justifyContent: 'flex-end',
						p: 2,
						backgroundColor: 'rgba(0, 0, 0, 0.02)',
					}}
				>
					<Button
						variant="outlined"
						color="primary"
						onClick={handleCancelClick}
						sx={{ mr: 1 }}
					>
						Peruuta
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSaveClick}
					>
						Tallenna
					</Button>
				</CardActions>
			)}
		</Card>
	);
};

export default ProfileInfo;
