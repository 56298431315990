import React, { useState } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Button,
	Switch,
	List,
	ListItem,
	ListItemSecondaryAction,
	IconButton,
	ListItemText,
} from '@mui/material';
import { AddCircleOutline, Delete as DeleteIcon } from '@mui/icons-material';

const RecruitDialog = ({ open, handleClose, onSubmit }) => {
	const [recruitData, setRecruitData] = useState({
		name: '',
		description: '',
		industry: '',
		startDate: '',
		endDate: '',
		teamRecruitment: false,
		useExistingForm: false,
		formId: '',
		questions: [],
	});

	const [questionText, setQuestionText] = useState('');

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		setRecruitData((prevData) => ({
			...prevData,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	const handleAddQuestion = () => {
		if (questionText) {
			setRecruitData((prevData) => ({
				...prevData,
				questions: [
					...prevData.questions,
					{ title: questionText.trim(), type: 'long_text' },
				],
			}));
			setQuestionText('');
		}
	};

	const handleDeleteQuestion = (index) => {
		setRecruitData((prevData) => ({
			...prevData,
			questions: prevData.questions.filter((_, i) => i !== index),
		}));
	};

	const handleSubmit = () => {
		onSubmit(recruitData);
		handleClose();
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Lisää rekry</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Voit lisätä uuden rekrytoinnin järjestelmään syöttämällä
					tarvittavat tiedot:
				</DialogContentText>
				<TextField
					margin="dense"
					id="name"
					name="name"
					label="Rekryn nimi"
					type="text"
					fullWidth
					variant="standard"
					value={recruitData.name}
					onChange={handleInputChange}
				/>
				<TextField
					margin="dense"
					id="description"
					name="description"
					label="Tehtävän kuvaus"
					fullWidth
					variant="standard"
					multiline
					rows={4}
					value={recruitData.description}
					onChange={handleInputChange}
				/>
				<TextField
					margin="dense"
					id="industry"
					name="industry"
					label="Toimiala"
					type="text"
					fullWidth
					variant="standard"
					value={recruitData.industry}
					onChange={handleInputChange}
				/>
				<TextField
					margin="dense"
					id="startDate"
					name="startDate"
					label="Aloituspäivämäärä"
					type="date"
					fullWidth
					variant="standard"
					InputLabelProps={{ shrink: true }}
					value={recruitData.startDate}
					onChange={handleInputChange}
				/>
				<TextField
					margin="dense"
					id="endDate"
					name="endDate"
					label="Päättymispäivämäärä"
					type="date"
					fullWidth
					variant="standard"
					InputLabelProps={{ shrink: true }}
					value={recruitData.endDate}
					onChange={handleInputChange}
				/>

				<List>
					{recruitData.questions.map((question, index) => (
						<ListItem key={index}>
							<ListItemText primary={question.title} />
							<ListItemSecondaryAction>
								<IconButton
									edge="end"
									aria-label="delete"
									onClick={() => handleDeleteQuestion(index)}
								>
									<DeleteIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>

				<TextField
					margin="dense"
					id="questionText"
					label="Lisää rekrykohtaiset kysymykset"
					fullWidth
					variant="standard"
					value={questionText}
					onChange={(e) => setQuestionText(e.target.value)}
					onKeyPress={(event) => {
						if (event.key === 'Enter') {
							event.preventDefault();
							handleAddQuestion();
						}
					}}
					disabled={recruitData.useExistingForm} // Disable if using an existing form
				/>
				<Button
					onClick={handleAddQuestion}
					color="primary"
					startIcon={<AddCircleOutline />}
					disabled={recruitData.useExistingForm}
				>
					Lisää kysymys
				</Button>

				<br />
				<label>
					Tiimirekrytointi
					<Switch
						checked={recruitData.teamRecruitment}
						onChange={handleInputChange}
						name="teamRecruitment"
					/>
				</label>
				<label>
					Käytä olemassa olevaa lomaketta
					<Switch
						checked={recruitData.useExistingForm}
						onChange={handleInputChange}
						name="useExistingForm"
					/>
				</label>
				{recruitData.useExistingForm && (
					<TextField
						margin="dense"
						id="formId"
						name="formId"
						label="Olemassa oleva Typeform ID"
						type="text"
						fullWidth
						variant="standard"
						value={recruitData.formId}
						onChange={handleInputChange}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Peruuta</Button>
				<Button
					onClick={handleSubmit}
					variant="contained"
					color="primary"
				>
					Lisää
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default RecruitDialog;
