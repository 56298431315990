import React, { useState } from 'react';
import {
	Typography,
	Grid,
	Card,
	Paper,
	Chip,
	Box,
	Avatar,
	IconButton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ApplicationPage from 'features/Application/components/ApplicationPage';
import Skills from './components/Skills';
import TalentLinks from './components/TalentLinks';
import CompleteProfileForm from 'features/Talent/components/CompleteProfileForm';
import ProfileInfo from './components/ProfileInfo';
import ProfileCompletion from './components/ProfileCompletion';
import Questionnaire from 'components/Questionnaire';
import TalentSkeleton from './components/TalentSkeleton';
import { useTalentData } from './hooks/useTalentData';
import { getRankLabel } from './utils/utils';
import TeamRolesRadarChart from './components/RadarChart';
import ApplicationCard from './components/ApplicationCard';
import { useProfileFormVisibility } from './hooks/useProfileFormVisibility';
import { selectIsTalent } from 'features/User/userSlice';
import { useTalentId } from './hooks/useTalentId';
import EditIcon from '@mui/icons-material/Edit';
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from '@mui/material/Tooltip';
import PotentialRadarChart from './components/PotentialRadarChart';
import questions from '../../questions/potentialtest/questions';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VideoModal from './components/VideoModal';

const Talent = () => {
	const talentId = useTalentId();
	const isTalent = useSelector(selectIsTalent);
	const { talent, isLoading } = useTalentData(talentId);
	const [isProfileFormOpen, setProfileFormOpen] = useProfileFormVisibility(
		isTalent,
		talent,
	);
	const [selectedApplication, setSelectedApplication] = useState(null);
	const [isDialogOpen, setDialogOpen] = useState(false);
	const [isQuestionnaireOpen, setIsQuestionnaireOpen] = useState(false);
	const [isVideoModalOpen, setVideoModalOpen] = useState(false);

	const handleCloseDialog = () => {
		setDialogOpen(false);
		setSelectedApplication(null);
	};

	const handleEditClick = () => {
		if (typeof setProfileFormOpen === 'function') {
			setProfileFormOpen(true);
		}
	};

	const checkForMissingFields = () => {
		return (
			isTalent &&
			(!talent?.city ||
				!talent?.university ||
				!talent?.stage ||
				!talent?.major ||
				!talent?.phone ||
				!talent?.category ||
				(Array.isArray(talent?.category) &&
					talent.category.length === 0))
		);
	};

	if (isLoading) return <TalentSkeleton />;

	return (
		<>
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					<Paper
						elevation={3}
						sx={{
							padding: 4,
							textAlign: 'center',
							backgroundColor:
								talent?.favoriteColor || 'primary.main',
							color: 'white',
							mb: 2,
							position: 'relative',
							height: { xs: '400px', md: '350px' },
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<IconButton
							onClick={handleEditClick}
							sx={{
								position: 'absolute',
								top: 8,
								right: 8,
								color: 'white',
								animation: checkForMissingFields()
									? 'blinker 1.5s linear infinite'
									: 'none',
								'@keyframes blinker': {
									'50%': { opacity: 0 },
								},
							}}
							title="Muokkaa profiilia"
						>
							<EditIcon />
						</IconButton>

						<Box sx={{ position: 'relative', mb: 3 }}>
							<Box
								sx={{
									position: 'relative',
									width: 150,
									height: 150,
									margin: '0 auto',
									cursor: talent?.videoUrl
										? 'pointer'
										: 'default',
									'&:hover .playIcon': {
										opacity: 1,
									},
								}}
								onClick={() =>
									talent?.videoUrl && setVideoModalOpen(true)
								}
							>
								<Avatar
									alt={talent?.user?.name?.split(' ')[0]}
									src={talent?.profilePicture}
									sx={{
										width: '100%',
										height: '100%',
										border: talent?.videoUrl
											? '4px solid #fa7921'
											: '4px solid rgba(255,255,255,0.2)',
										boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
									}}
								/>
								{talent?.videoUrl && (
									<PlayCircleIcon
										className="playIcon"
										sx={{
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
											fontSize: { xs: 40, md: 50 },
											color: '#fa7921',
											opacity: { xs: 1, md: 0 },
											transition:
												'opacity 0.2s ease-in-out',
											backgroundColor:
												'rgba(255,255,255,0.8)',
											borderRadius: '50%',
											zIndex: 1,
										}}
									/>
								)}
							</Box>
							<Tooltip
								title={
									<Typography
										sx={{ p: 1, fontSize: '0.875rem' }}
									>
										<Typography
											variant="h6"
											sx={{ fontWeight: 'bold' }}
										>
											Verified By Lenz
										</Typography>
										<Typography sx={{ my: 1 }}>
											{talent?.user?.name} suoriutui
											erinomaisesti potentiaalitestistä,
											case tehtävästä ja
											live-haastattelusta.
										</Typography>
										<Typography sx={{ my: 1 }}>
											Lue lisää Lenzin valintaprosessista
											osoitteessa lenz.fi.
										</Typography>
									</Typography>
								}
								arrow
								placement="bottom"
								enterTouchDelay={0}
								leaveTouchDelay={3000}
							>
								<Box
									sx={{
										position: 'absolute',
										bottom: -10,
										left: '50%',
										transform: 'translateX(-50%)',
										bgcolor: 'white',
										borderRadius: 20,
										px: 1.5,
										py: 0.5,
										display: 'flex',
										alignItems: 'center',
										boxShadow: 1,
										cursor: 'help',
									}}
								>
									<VerifiedIcon
										sx={{
											color: 'primary.main',
											fontSize: 16,
											mr: 0.5,
										}}
									/>
									<Typography
										variant="caption"
										sx={{
											color: 'primary.main',
											fontWeight: 'bold',
										}}
									>
										Verified By Lenz
									</Typography>
								</Box>
							</Tooltip>
						</Box>

						<Typography
							variant="h4"
							sx={{ fontWeight: 'bold', mb: 2 }}
						>
							{talent?.user?.name || talent?.name}
						</Typography>

						{talent?.motto && (
							<Typography
								variant="body1"
								sx={{ fontStyle: 'italic', mb: 2 }}
							>
								&ldquo;{talent.motto}&rdquo;
							</Typography>
						)}

						{talent?.category?.length > 0 && (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									gap: 1,
									flexWrap: 'wrap',
								}}
							>
								{talent.category.map((category, index) => (
									<Chip
										key={index}
										label={category}
										sx={{
											backgroundColor:
												'rgba(255, 255, 255, 0.2)',
											color: '#fff',
											height: 28,
											fontSize: '0.875rem',
										}}
									/>
								))}
							</Box>
						)}
					</Paper>

					<ProfileCompletion
						isTalent={isTalent}
						talent={talent}
						color={talent.favoriteColor || 'primary.main'}
						open={() => setIsQuestionnaireOpen(true)}
						talentId={talentId}
					/>

					<ProfileInfo
						talentId={talentId}
						talent={talent}
						isTalent={isTalent}
						color={talent?.favoriteColor || 'primary.main'}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: { xs: '1fr', lg: '1fr 1fr' },
							gap: 3,
							mb: 3,
						}}
					>
						<TeamRolesRadarChart
							teamRoles={talent?.teamrole}
							favoriteColor={talent?.favoriteColor}
						/>
						<PotentialRadarChart
							potentialTest={talent?.potentialTest}
							favoriteColor={talent?.favoriteColor}
						/>
					</Box>
					<Skills
						skills={talent?.skills}
						talentId={talentId}
						color={talent?.favoriteColor}
					/>

					<Paper
						elevation={3}
						sx={{ padding: 3, marginTop: 4, marginBottom: 4 }}
					>
						<Typography
							variant="h5"
							gutterBottom
							sx={{ fontWeight: 'bold' }}
						>
							Hakemukset
						</Typography>
						{!(talent?.applications.length === 0) ? (
							talent?.applications.map((application, index) => (
								<ApplicationCard
									key={index}
									application={application}
									onClick={() => {
										setSelectedApplication(application);
										setDialogOpen(true);
									}}
								/>
							))
						) : (
							<Card sx={{ my: 1, p: 2 }}>
								<Typography>
									Et ole vielä hakenut Lenzin projekteihin.
									<Box
										component={Link}
										to="/app/recruit"
										sx={{
											textDecoration: 'none',
											color: 'primary.main',
											fontWeight: 'bold',
											mx: 1,
											'&:hover': {
												textDecoration: 'underline',
												color: 'primary.dark',
											},
										}}
									>
										Tutustu rekrytointimahdollisuuksiin
									</Box>
									ja lähetä hakemuksesi.
								</Typography>
							</Card>
						)}
					</Paper>
					<TalentLinks talentId={talentId} links={talent?.links} />
				</Grid>

				<Questionnaire
					talentId={talentId}
					sections={questions.sections}
					open={isQuestionnaireOpen}
					onClose={() => setIsQuestionnaireOpen(false)}
				/>

				<ApplicationPage
					application={selectedApplication}
					open={isDialogOpen}
					onClose={handleCloseDialog}
				/>
			</Grid>

			<CompleteProfileForm
				open={isProfileFormOpen}
				onClose={() => {
					if (typeof setProfileFormOpen === 'function') {
						setProfileFormOpen(false);
					}
				}}
				initialData={talent}
			/>

			<VideoModal
				open={isVideoModalOpen}
				onClose={() => setVideoModalOpen(false)}
				videoUrl={talent?.videoUrl}
			/>
		</>
	);
};

export default Talent;
