import React from 'react';
import { Widget } from '@typeform/embed-react';
import { Modal, Container } from '@mui/material';

const TypeformDialog = ({ formId, talentId, open, onClose }) => {
	return (
		<Modal open={open} onClose={onClose}>
			<Container
				sx={{
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					position: 'absolute',
					width: '80vw', // Lisää leveys
					maxWidth: '1000px', // Maksimi leveys
					height: '80vh',
					overflow: 'hidden',
					padding: 0, // Poistetaan padding
				}}
			>
				<div
					onClick={(e) => e.stopPropagation()}
					style={{ width: '100%', height: '100%', padding: 0 }}
				>
					<Widget
						id={formId}
						hidden={{ user_id: talentId }}
						style={{
							width: '100%',
							height: '100%',
							padding: 0,
							margin: 0,
						}} // Poistetaan padding ja marginaalit
						className="my-form"
					/>
				</div>
			</Container>
		</Modal>
	);
};

export default TypeformDialog;
