import { createSlice } from '@reduxjs/toolkit';

const talentSlice = createSlice({
	name: 'talent',
	initialState: null,
	reducers: {
		setTalent: (state, action) => action.payload,
	},
});

export const { setTalent } = talentSlice.actions;

export default talentSlice.reducer;
