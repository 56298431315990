import React from 'react';
import { Box, Grid, Skeleton, Card, CardContent } from '@mui/material';

const TalentSkeleton = () => {
	return (
		<Grid container spacing={4}>
			<Grid item xs={12} sm={6}>
				<Card sx={{ height: '100%' }}>
					<CardContent>
						<Skeleton variant="text" height={40} width="60%" />
						<Skeleton variant="text" height={20} width="40%" />
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								mt: 2,
							}}
						>
							<Skeleton
								variant="circular"
								width={40}
								height={40}
							/>
							<Skeleton
								variant="text"
								height={20}
								width="30%"
								sx={{ ml: 2 }}
							/>
						</Box>
						<Skeleton
							variant="rectangular"
							height={20}
							width="100%"
							sx={{ mt: 2 }}
						/>
						<Skeleton
							variant="rectangular"
							height={100}
							width="100%"
							sx={{ mt: 2 }}
						/>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Card sx={{ height: '100%' }}>
					<CardContent>
						<Skeleton variant="text" height={40} width="60%" />
						<Skeleton variant="text" height={20} width="40%" />
						<Skeleton
							variant="rectangular"
							height={200}
							width="100%"
							sx={{ mt: 2 }}
						/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default TalentSkeleton;
