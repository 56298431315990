import React from 'react';
import { Link } from 'react-router-dom';
import {
	Card,
	CardContent,
	Typography,
	Button,
	Box,
	Chip,
	Grid,
	Divider,
	useTheme,
	Paper,
	Alert,
	Stack,
	LinearProgress,
} from '@mui/material';
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	ResponsiveContainer,
} from 'recharts';
import SchoolIcon from '@mui/icons-material/School';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PersonIcon from '@mui/icons-material/Person';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';

const TalentCard = ({
	talent,
	vs_score,
	onAddToTeam,
	isInTeam,
	index,
	isAuthenticated,
}) => {
	const theme = useTheme();

	const teamRoleData = Object.keys(talent.teamrole || {}).map((role) => ({
		subject: role.charAt(0).toUpperCase() + role.slice(1),
		A: talent.teamrole[role],
	}));

	return (
		<Card elevation={3}>
			<CardContent>
				{/* Header */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						mb: 2,
					}}
				>
					<Box>
						<Typography variant="h6" gutterBottom>
							Talentti {index + 1}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{talent.university}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{talent.major || 'Tietotekniikka'}
						</Typography>
						<Button
							component={Link}
							to={`/app/talents/${talent.id}`}
							size="small"
							sx={{ mt: 1 }}
							startIcon={<PersonSearchIcon />}
							target="_blank"
							rel="noopener noreferrer"
						>
							Katso profiili
						</Button>
					</Box>
					<Box sx={{ textAlign: 'right' }}>
						<Typography variant="h6" color="primary">
							{isAuthenticated
								? `${talent.hourlyRate || 45}€/h`
								: '45€/h'}
							{!isAuthenticated && (
								<Typography
									variant="caption"
									sx={{ display: 'block', opacity: 0.7 }}
								>
									(arvio)
								</Typography>
							)}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							Sopivuus: {Math.round(vs_score * 100)}%
						</Typography>
					</Box>
				</Box>

				<Divider sx={{ my: 2 }} />

				{/* Skills */}
				<Typography variant="subtitle1" gutterBottom>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<WorkspacePremiumIcon color="primary" />
						Taidot
					</Box>
				</Typography>
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						gap: 1,
						mb: 3,
					}}
				>
					{Object.entries(talent.skills || {}).map(([_, skill]) => (
						<Chip
							key={skill.skill}
							label={skill.skill}
							size="small"
							sx={{
								backgroundColor: theme.palette.primary.light,
								color: theme.palette.primary.contrastText,
								'&:hover': {
									backgroundColor: theme.palette.primary.main,
								},
								fontWeight: 500,
								border: `1px solid ${theme.palette.primary.main}`,
							}}
						/>
					))}
				</Box>

				<Divider sx={{ my: 2 }} />

				{/* Team Role Chart */}
				<Typography variant="subtitle1" gutterBottom>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<PersonIcon color="primary" />
						Tiimirooli
					</Box>
				</Typography>
				<Box sx={{ height: 250, width: '100%', mt: 2 }}>
					<ResponsiveContainer>
						<RadarChart data={teamRoleData}>
							<PolarGrid />
							<PolarAngleAxis
								dataKey="subject"
								tick={{
									fill: theme.palette.text.secondary,
									fontSize: 12,
								}}
							/>
							<Radar
								name="Rooli"
								dataKey="A"
								stroke={theme.palette.primary.main}
								fill={theme.palette.primary.main}
								fillOpacity={0.6}
							/>
						</RadarChart>
					</ResponsiveContainer>
				</Box>

				{/* Action Button */}
				<Box
					sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}
				>
					<Button
						variant={isInTeam ? 'outlined' : 'contained'}
						onClick={onAddToTeam}
						disabled={isInTeam}
						startIcon={isInTeam ? <CheckIcon /> : <AddIcon />}
					>
						{isInTeam ? 'Lisätty tiimiin' : 'Lisää tiimiin'}
					</Button>
				</Box>
			</CardContent>
		</Card>
	);
};

export default TalentCard;
