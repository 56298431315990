import React from 'react';
import { useRouteError } from 'react-router-dom';
import logo from '../logo.png';

const ErrorPage = () => {
	const error = useRouteError();
	console.error(error);
	return (
		<div id="error-page">
			<h1>Oops!</h1>
			<img src={logo} />
			<p>Sorry, an unexpected error has occurred.</p>
			<p>
				<i>{error.statusText || error.message}</i>
			</p>
		</div>
	);
};

export default ErrorPage;
