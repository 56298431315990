import React, { useState, useMemo } from 'react';
import {
	LinearProgress,
	Box,
	Typography,
	Card,
	CardContent,
	Button,
	FormControlLabel,
	Checkbox,
	Chip,
	IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TypeformDialog from 'components/TypeformDialog';
import { useMutation, useQueryClient } from 'react-query';
import { talentService } from 'services';

const calculateProgress = (talent) => {
	if (!talent) return 0;

	let filledFields = 0;
	const totalFields =
		7 +
		(talent.deepTest?.length || 0) *
			(talent.deepTest[0]?.responses?.length || 0);

	if (talent.city) filledFields++;
	if (talent.university) filledFields++;
	if (talent.stage) filledFields++;
	if (talent.major) filledFields++;
	if (
		talent.teamrole &&
		Object.values(talent.teamrole).some((value) => value > 0)
	)
		filledFields++;
	if (talent.weeklyAvailability) filledFields++;
	if (talent.isActivelySeekingWork !== undefined) filledFields++;

	talent.deepTest?.forEach((section) => {
		section.responses.forEach((response) => {
			if (response.answer !== null && response.answer !== '')
				filledFields++;
		});
	});

	return (filledFields / totalFields) * 100;
};

const isTestComplete = (deepTest) => {
	return (
		deepTest?.length > 0 &&
		deepTest.every((section) =>
			section.responses.every(
				(response) =>
					response.answer !== null && response.answer !== '',
			),
		)
	);
};

const ProfileCompletion = ({ talent, isTalent, color, open, talentId }) => {
	const queryClient = useQueryClient();
	const [isTeamroleTestOpen, setTeamroleTestOpen] = useState(false);
	const [availability, setAvailability] = useState(
		talent?.weeklyAvailability || '',
	);
	const [isSeekingWork, setIsSeekingWork] = useState(
		talent?.isActivelySeekingWork || false,
	);

	const progress = useMemo(() => calculateProgress(talent), [talent]);
	const deepTestComplete = useMemo(
		() => isTestComplete(talent?.deepTest),
		[talent],
	);

	const updateTalentMutation = useMutation(
		(updatedData) => talentService.updateTalentById(talentId, updatedData),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['talentData', talentId]);
			},
		},
	);

	const handleAvailabilityChange = (range) => {
		setAvailability(range);
		updateTalentMutation.mutate({ weeklyAvailability: range });
	};

	const handleSeekingWorkChange = (event) => {
		const isChecked = event.target.checked;
		setIsSeekingWork(isChecked);
		updateTalentMutation.mutate({ isActivelySeekingWork: isChecked });
	};

	if (progress === 100 || !isTalent) {
		return null; // Piilotetaan kortti, jos progress on 100%
	}

	return (
		<>
			<Card sx={{ mb: 2 }}>
				<CardContent>
					<Typography variant="subtitle1">
						Viimeistele profiilisi
					</Typography>
					<Box sx={{ width: '100%' }}>
						<Typography variant="subtitle2" color="text.secondary">
							Profiilin täydentäminen: {progress?.toFixed(1) || 0}
							%
						</Typography>
						<LinearProgress
							variant="determinate"
							value={progress || 0}
							sx={{
								height: 10,
								borderRadius: 5,
								mt: 1,
								backgroundColor: '#e0e0e0',
								'& .MuiLinearProgress-bar': {
									backgroundColor: color,
								},
							}}
						/>

						{isTalent && (
							<Box sx={{ mt: 2 }}>
								<Button
									variant="outlined"
									startIcon={<EditIcon />}
									onClick={open}
									sx={{ mb: 2 }}
								>
									Muokkaa syventävää kyselyä
								</Button>
								{!deepTestComplete && (
									<Typography
										variant="subtitle2"
										color="text.secondary"
									>
										Täytä syventävä kysely, jotta voimme
										ehdottaa sinulle sopivia tehtäviä.
									</Typography>
								)}
							</Box>
						)}

						{isTalent && (
							<Box sx={{ mt: 2 }}>
								<Button
									variant="outlined"
									startIcon={<EditIcon />}
									onClick={() => setTeamroleTestOpen(true)}
									sx={{ mb: 2 }}
								>
									Muokkaa tiimiroolitestiä
								</Button>
								{!(
									talent?.teamrole &&
									Object.values(talent?.teamrole).some(
										(role) => role > 0,
									)
								) && (
									<Typography
										variant="subtitle2"
										color="text.secondary"
									>
										Tee tiimiroolitesti, jotta voimme
										ehdottaa sinua sopiviin projekteihin.
									</Typography>
								)}
							</Box>
						)}

						{isTalent && (
							<>
								<Typography
									variant="subtitle2"
									color="text.secondary"
									sx={{ mt: 2, mb: 1 }}
								>
									Käytettävyys viikossa:
								</Typography>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										gap: 1,
									}}
								>
									<Chip
										label="1-10 h"
										onClick={() =>
											handleAvailabilityChange('1-10 h')
										}
										color={
											availability === '1-10 h'
												? 'primary'
												: 'default'
										}
										clickable
									/>
									<Chip
										label="10-20 h"
										onClick={() =>
											handleAvailabilityChange('10-20 h')
										}
										color={
											availability === '10-20 h'
												? 'primary'
												: 'default'
										}
										clickable
									/>
									<Chip
										label="20-40 h"
										onClick={() =>
											handleAvailabilityChange('20-40 h')
										}
										color={
											availability === '20-40 h'
												? 'primary'
												: 'default'
										}
										clickable
									/>
								</Box>
								<FormControlLabel
									control={
										<Checkbox
											checked={isSeekingWork}
											onChange={handleSeekingWorkChange}
											sx={{
												color: 'primary.main',
												'&.Mui-checked': {
													color: 'primary.main',
												},
											}}
										/>
									}
									label="Haen aktiivisesti töitä"
									sx={{ mt: 2 }}
								/>
							</>
						)}
					</Box>
				</CardContent>
			</Card>
			<TypeformDialog
				talentId={talentId}
				formId="VbCjrM46"
				open={isTeamroleTestOpen}
				onClose={() => setTeamroleTestOpen(false)}
			/>
		</>
	);
};

export default ProfileCompletion;
