import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearNotification } from '../notificationSlice';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledDialogTitle = styled(DialogTitle)(({ theme, severity }) => ({
	backgroundColor:
		severity === 'error'
			? theme.palette.error.main
			: theme.palette.success.main,
	color: theme.palette.error.contrastText,
}));

const StyledDialogContent = styled(DialogContent)(({ theme, severity }) => ({
	backgroundColor:
		severity === 'error'
			? theme.palette.error.main
			: theme.palette.success.main,
	color: theme.palette.error.contrastText,
}));

const NotificationDialog = () => {
	const { error, success } = useSelector((state) => state.notification);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(clearNotification());
	};

	if (!error && !success) {
		return null;
	}

	const severity = error ? 'error' : 'success';
	const message = error || success;

	return (
		<Dialog open={!!message} onClose={handleClose}>
			<StyledDialogTitle severity={severity}>
				{error ? 'Error' : 'Success'}
			</StyledDialogTitle>
			<StyledDialogContent severity={severity}>
				{message}
			</StyledDialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default NotificationDialog;
