import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { userService } from 'services';
import {
	Box,
	Button,
	TextField,
	Select,
	MenuItem,
	Typography,
	Grid,
	CircularProgress,
	Alert,
	Paper,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	DialogContent,
} from '@mui/material';

const ManagePage = () => {
	const [newUser, setNewUser] = useState({
		name: '',
		email: '',
		password: '',
		role: '',
		talentId: '',
	});
	const [openDialog, setOpenDialog] = useState(false);
	const [userIdToDelete, setUserIdToDelete] = useState(null);
	const queryClient = useQueryClient();
	const {
		data: users,
		isLoading,
		error,
	} = useQuery('users', userService.getUsers);

	const deleteUserMutation = useMutation(userService.deleteUser, {
		onSuccess: () => {
			queryClient.invalidateQueries('users');
		},
	});

	const updateUserRoleMutation = useMutation(userService.updateUserById);

	const addUserMutation = useMutation(userService.addUser, {
		onSuccess: () => {
			setNewUser({
				name: '',
				email: '',
				password: '',
				role: '',
				talentId: '',
			});
			queryClient.invalidateQueries('users');
		},
	});

	const handleOpenDialog = (userId) => {
		setOpenDialog(true);
		setUserIdToDelete(userId);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const confirmDeleteUser = () => {
		if (userIdToDelete) {
			deleteUserMutation.mutate(userIdToDelete);
			setUserIdToDelete(null);
			handleCloseDialog();
		}
	};

	const deleteUser = (userId) => {
		handleOpenDialog(userId);
	};

	const updateUserRole = (userId, newRole) => {
		if (newRole) {
			updateUserRoleMutation.mutate({ userId, role: newRole });
		}
	};

	const addUser = () => {
		const { name, email, password, role, talentId } = newUser;
		if (name && email && password && role) {
			const payload = { name, email, password, role };
			if (role === 'talent' && talentId) {
				payload.talentId = talentId;
			}
			addUserMutation.mutate(payload);
		} else {
			alert('Please enter name, email, password, and role');
		}
	};

	if (isLoading) return <CircularProgress />;
	if (error)
		return (
			<Alert severity="error">
				{error.message}
				<Button onClick={() => queryClient.invalidateQueries('users')}>
					Retry
				</Button>
			</Alert>
		);

	return (
		<Box>
			<Typography variant="h4" gutterBottom>
				User Management
			</Typography>
			<Grid container spacing={2}>
				{users.data.results.map((user) => (
					<Grid item xs={12} md={6} lg={4} key={user.id}>
						<Paper
							sx={{
								p: 2,
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							}}
						>
							<Typography variant="body1">
								{user.name} - {user.role}
							</Typography>
							<Typography variant="subtitle2">
								{user.email}
							</Typography>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									mt: 2,
								}}
							>
								<Button
									variant="outlined"
									color="error"
									size="small"
									onClick={() => deleteUser(user.id)}
								>
									Delete
								</Button>
								<Select
									size="small"
									value={user.role}
									onChange={(e) =>
										updateUserRole(user.id, e.target.value)
									}
									sx={{ flexGrow: 1, ml: 2 }}
								>
									<MenuItem value="admin">Admin</MenuItem>
									<MenuItem value="talent">Talent</MenuItem>
									<MenuItem value="company">Company</MenuItem>
								</Select>
							</Box>
						</Paper>
					</Grid>
				))}
			</Grid>
			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{'Confirm Delete'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this user?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog}>Cancel</Button>
					<Button onClick={confirmDeleteUser} autoFocus color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Box
				sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 2 }}
			>
				<TextField
					label="Name"
					value={newUser.name}
					onChange={(e) =>
						setNewUser({ ...newUser, name: e.target.value })
					}
					autoComplete="off" // Estä nimen automaattitäyttö
				/>
				<TextField
					label="Email"
					value={newUser.email}
					onChange={(e) =>
						setNewUser({ ...newUser, email: e.target.value })
					}
					autoComplete="off" // Estä sähköpostin automaattitäyttö
				/>
				<TextField
					label="Password"
					type="password"
					value={newUser.password}
					onChange={(e) =>
						setNewUser({ ...newUser, password: e.target.value })
					}
					autoComplete="new-password" // Estä salasanan automaattitäyttö
				/>
				<Select
					label="Role"
					value={newUser.role}
					onChange={(e) =>
						setNewUser({
							...newUser,
							role: e.target.value,
							talentId: '',
						})
					}
					// Voit myös lisätä autoComplete="off" Select-komponenttiin, jos tarpeellista
				>
					<MenuItem value="admin">Admin</MenuItem>
					<MenuItem value="talent">Talent</MenuItem>
					<MenuItem value="company">Company</MenuItem>
				</Select>
				{newUser.role === 'talent' && (
					<TextField
						label="Talent ID"
						value={newUser.talentId}
						onChange={(e) =>
							setNewUser({ ...newUser, talentId: e.target.value })
						}
						autoComplete="off" // Estä talent ID:n automaattitäyttö
					/>
				)}
				<Button variant="contained" color="primary" onClick={addUser}>
					Add User
				</Button>
			</Box>
		</Box>
	);
};

export default ManagePage;
