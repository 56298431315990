import { createSlice } from '@reduxjs/toolkit';

const recruitSlice = createSlice({
	name: 'recruit',
	initialState: null,
	reducers: {
		setRecruit: (state, action) => action.payload,
	},
});

export const { setRecruit } = recruitSlice.actions;

export default recruitSlice.reducer;
