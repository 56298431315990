import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@mui/material';

const ModalComponent = ({ open, onClose, title, children, actions }) => (
	<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
		<DialogTitle>{title}</DialogTitle>
		<DialogContent>{children}</DialogContent>
		<DialogActions>{actions}</DialogActions>
	</Dialog>
);

export default ModalComponent;
