import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import getLPTheme from './getLPTheme';
import Contact from './components/Contact';
import RecruitsWidget from 'features/Recruits/components/RecruitsWidget';
import CompanyHero from './components/CompanyHero';
import Modal from '@mui/material/Modal';
import TalentChatView from './components/TalentChatView';

export const RoleSelectionModal = ({ open, handleClose, setUserRole }) => (
	<Modal open={open} onClose={handleClose}>
		<Box
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				backgroundColor: 'hsla(220, 0%, 0%, 0.8)',
				borderRadius: 2,
				boxShadow:
					'0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.2), 0 0 30px 10px hsla(210, 100%, 80%, 0.2)', // pienempi efekti
				p: 4,
				maxWidth: 500,
				textAlign: 'center',
				'&:focus': {
					outline: 'none',
				},
			}}
		>
			<Typography variant="h4" component="h2" gutterBottom>
				Tervetuloa{' '}
				<Typography
					variant="h2"
					sx={(theme) => ({ color: 'primary.main' })}
				>
					Lenz Platformille
				</Typography>
			</Typography>
			<Typography variant="body1" sx={{ mb: 3 }}>
				Personoidaksemme käyttökokemuksen, valitse käyttäjäroolisi. Tämä
				auttaa meitä tarjoamaan sinulle parhaiten sopivaa sisältöä ja
				ominaisuuksia.
			</Typography>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					gap: 2,
					pt: 2,
				}}
			>
				<Button
					variant="contained"
					onClick={() => setUserRole('company')}
				>
					Yritys
				</Button>
				<Button
					variant="contained"
					onClick={() => setUserRole('student')}
				>
					Opiskelija
				</Button>
			</Box>
		</Box>
	</Modal>
);

RoleSelectionModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	setUserRole: PropTypes.func.isRequired,
};

export default function LandingPageV2() {
	const [userRole, setUserRole] = useState('company'); // Aseta oletusarvo
	const [theme, setTheme] = useState(null);

	useEffect(() => {
		const sessionRole = sessionStorage.getItem('userRole');
		if (sessionRole) {
			setUserRole(sessionRole);
		} else {
			setUserRole('company');
			sessionStorage.setItem('userRole', 'company');
		}
	}, []);

	useEffect(() => {
		setTheme(createTheme(getLPTheme(userRole)));
	}, [userRole]);

	const handleSetUserRole = (role) => {
		setUserRole(role);
		sessionStorage.setItem('userRole', role);
	};

	if (!theme) return null; // Odota, kunnes teema on ladattu

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<AppAppBar userRole={userRole} setUserRole={handleSetUserRole} />
			<Box
				sx={{
					bgcolor: 'background.default',
					overflow: 'hidden', // Estetään horisontaalinen scroll mobiilissa
				}}
			>
				<Hero userRole={userRole} />

				<LogoCollection />
				<Features userRole={userRole} />
				<Divider />
				<Testimonials userRole={userRole} />
				{userRole === 'student' && (
					<>
						<Divider />
						<RecruitsWidget />
					</>
				)}
				<Divider />
				<Contact userRole={userRole} />
			</Box>
		</ThemeProvider>
	);
}
