const calculateAverageTeamRoles = (applicants) => {
	const roleScores = {
		visionary: 0,
		researcher: 0,
		challenger: 0,
		teamPlayer: 0,
		implementer: 0,
		analyst: 0,
		coordinator: 0,
		specialist: 0,
		finisher: 0,
	};
	let validTalents = 0;

	applicants?.forEach((applicant) => {
		// Tarkistetaan, että applicant ja applicant.teamrole ovat määriteltyjä
		if (applicant && applicant.teamrole) {
			Object.keys(applicant.teamrole).forEach((role) => {
				// Varmistetaan, että rooli kuuluu käsiteltäviin rooleihin
				if (Object.prototype.hasOwnProperty.call(roleScores, role)) {
					roleScores[role] += applicant.teamrole[role];
				}
			});
			validTalents++;
		}
	});

	// Jos löytyi kelvollisia talenteja, lasketaan keskiarvot
	if (validTalents > 0) {
		Object.keys(roleScores).forEach((role) => {
			roleScores[role] = roleScores[role] / validTalents;
		});
	} else {
		console.log('Ei löytynyt kelvollisia talenteja.');
	}

	return roleScores;
};

/**
 * Laskee keskimääräiset arvosanat hakijoiden taidoille.
 * @param {Array} applicants - Hakijat, joilla kullakin on `skills`-taulukko.
 * @returns {Object} - Taitojen keskiarvot.
 */
const calculateAverageSkills = (applicants) => {
	const skillSums = {};
	let totalApplicants = 0;

	applicants.forEach((applicant) => {
		if (applicant && applicant.skills) {
			applicant.skills.forEach((skill) => {
				if (skillSums[skill.skill]) {
					skillSums[skill.skill].total += skill.rating;
					skillSums[skill.skill].count += 1;
				} else {
					skillSums[skill.skill] = { total: skill.rating, count: 1 };
				}
			});
			totalApplicants++;
		}
	});

	const averageSkills = {};
	Object.keys(skillSums).forEach((skill) => {
		averageSkills[skill] = skillSums[skill].total / skillSums[skill].count;
	});

	return averageSkills;
};

const createRadarChartData = (data, title) => {
	return {
		labels: Object.keys(data),
		datasets: [
			{
				label: title,
				backgroundColor: 'rgba(255, 99, 132, 0.2)',
				borderColor: 'rgba(255, 99, 132, 1)',
				pointBackgroundColor: 'rgba(255, 99, 132, 1)',
				pointBorderColor: '#fff',
				pointHoverBackgroundColor: '#fff',
				pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
				data: Object.values(data),
			},
		],
	};
};

export {
	calculateAverageTeamRoles,
	calculateAverageSkills,
	createRadarChartData,
};
