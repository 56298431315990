import { redirect } from 'react-router-dom';

export function getAuthToken() {
	const token = localStorage.getItem('loggedUser');
	console.log(token);
	return JSON.parse(token);
}

export function logOut() {
	window.localStorage.removeItem('loggedUser');
	console.log('kirjattu ulos');
}

export function checkAuthLoader() {
	const token = getAuthToken();

	if (!token) {
		return redirect('/login');
	}
	return null;
}
