import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import {
	Typography,
	Card,
	CardContent,
	Button,
	Box,
	Grid,
	useTheme,
	Divider,
	Container,
} from '@mui/material';
import { recruitService } from '../../../services';
import RecruitCard from './RecruitCard';
import { useNavigate } from 'react-router-dom';

const WidgetPage = () => {
	const theme = useTheme();
	const {
		data: recruits,
		isLoading,
		error,
	} = useQuery('recruits', recruitService.getOpenRecruits);
	const [selectedRecruit, setSelectedRecruit] = useState(null);
	const cardRef = useRef(null);
	const navigate = useNavigate();

	const openRecruits = recruits?.data.results;

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (cardRef.current && !cardRef.current.contains(event.target)) {
				setSelectedRecruit(null);
			}
		};

		if (selectedRecruit) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [selectedRecruit]);

	return (
		<Container
			id="recruitments"
			sx={{
				pt: { xs: 4, sm: 12 },
				pb: { xs: 8, sm: 16 },
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: { xs: 3, sm: 6 },
			}}
		>
			<Typography variant="h4" gutterBottom align="center">
				Avoimet rekrytoinnit
			</Typography>
			{selectedRecruit ? (
				<Card
					ref={cardRef}
					sx={{
						m: 2,
						boxShadow: 3,
						borderRadius: '16px',
						padding: '20px',
					}}
				>
					<CardContent>
						<Typography variant="h4" component="div" gutterBottom>
							{selectedRecruit?.name}
						</Typography>
						<Typography variant="h6" component="div" gutterBottom>
							{selectedRecruit?.company?.name}
						</Typography>
						<Divider sx={{ mb: 2 }} />
						<Typography
							variant="body1"
							color="text.secondary"
							component="pre"
							sx={{ whiteSpace: 'pre-wrap' }}
							paragraph
						>
							{selectedRecruit.description}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								mt: 3,
							}}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={() => navigate('/login')}
							>
								Hae tehtävään
							</Button>
							<Button
								variant="outlined"
								size="small"
								onClick={() => setSelectedRecruit(null)}
							>
								Takaisin
							</Button>
						</Box>
					</CardContent>
				</Card>
			) : (
				<Grid container spacing={3}>
					{openRecruits?.length === 0 ? (
						<Grid item xs={12}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Typography align="center">
									Valitettavasti juuri nyt ei ole saatavilla
									julkisia rekrytointeja. Rekisteröidy
									saadaksesi juuri sinulle sopivia ehdotuksia.
								</Typography>
							</Box>
						</Grid>
					) : (
						openRecruits?.map((recruit) => (
							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								lg={4}
								xl={4}
								key={recruit.id}
							>
								<RecruitCard
									recruit={recruit}
									onClick={() => setSelectedRecruit(recruit)}
								/>
							</Grid>
						))
					)}
				</Grid>
			)}
		</Container>
	);
};

export default WidgetPage;
