import axios from './axios';

const getTalents = async () => {
	const response = await axios.get('/talents?limit=200');
	return response;
};

const getTalent = async (id) => {
	const response = await axios.get(`/talents/${id}`);
	return response;
};

const updateTalentById = async (id, newObject) => {
	const response = await axios.patch(`/talents/${id}`, newObject);
	return response.data;
};

const hybridSearch = async (query) => {
	const response = await axios.post(`/talents/hybridSearch`, { query });
	return response.data;
};

const createTeam = async (teamData) => {
	const response = await axios.post('/teams', teamData);
	return response.data;
};

export { getTalent, getTalents, updateTalentById, hybridSearch, createTeam };
