import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	Button,
	Typography,
	Box,
	TextField,
	Slider,
	Paper,
	FormControlLabel,
	Switch,
	Grid,
	Tabs,
	Tab,
	Modal,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	CircularProgress,
} from '@mui/material';
import { talentService } from 'services';

const Questionnaire = ({ talentId, sections, onClose, open }) => {
	const queryClient = useQueryClient();
	const isFormTouched = useRef(false); // Track if the form has been touched by the user
	const [answers, setAnswers] = useState({});
	const [currentTab, setCurrentTab] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [unsavedChanges, setUnsavedChanges] = useState(false);

	const initializeAnswers = (initialData = {}) => {
		const initialAnswers = {};
		sections.forEach((section) => {
			section.questions.forEach((question) => {
				if (initialData[question.name] !== undefined) {
					initialAnswers[question.name] = initialData[question.name];
				} else if (
					question.type === 'number' ||
					question.type === 'opinion'
				) {
					initialAnswers[question.name] = question.min;
				} else {
					initialAnswers[question.name] = '';
				}
			});
		});
		console.log('Initialized answers:', initialAnswers);
		return initialAnswers;
	};

	const { data: initialData, isLoading: loadingInitialData } = useQuery(
		['talentData', talentId],
		() => talentService.getTalent(talentId),
		{
			enabled: open,
			onSuccess: (data) => {
				if (!isFormTouched.current) {
					console.log('Fetched talent data:', data);
					const deepTestResponses = data?.data?.deepTest?.reduce(
						(acc, section) => {
							section.responses.forEach((response) => {
								const questionName = sections
									.flatMap((section) => section.questions)
									.find(
										(question) =>
											question.label ===
											response.question,
									)?.name;
								if (questionName) {
									acc[questionName] = response.answer;
								}
							});
							return acc;
						},
						{},
					);
					console.log('Deep test responses:', deepTestResponses);
					setAnswers(initializeAnswers(deepTestResponses));
				}
			},
		},
	);

	useEffect(() => {
		if (!open) {
			console.log('Modal closed, resetting answers');
			setAnswers(initializeAnswers());
			setCurrentTab(0);
			isFormTouched.current = false; // Reset form touched status when the modal is closed
		}
	}, [open, sections]);

	const handleChange = (e) => {
		isFormTouched.current = true; // Mark the form as touched
		const { name, value, type, checked } = e.target;
		setAnswers((prevAnswers) => ({
			...prevAnswers,
			[name]: type === 'checkbox' ? checked : value,
		}));
		setUnsavedChanges(true);
		console.log('Updated answers:', answers);
	};

	const handleSliderChange = (name) => (e, value) => {
		isFormTouched.current = true; // Mark the form as touched
		setAnswers((prevAnswers) => ({
			...prevAnswers,
			[name]: value,
		}));
		setUnsavedChanges(true);
		console.log('Updated answers with slider:', answers);
	};

	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const mutation = useMutation(
		(newAnswers) =>
			talentService.updateTalentById(talentId, { deepTest: newAnswers }),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['talentData', talentId]);
				setUnsavedChanges(false);
			},
		},
	);

	const handleSubmit = () => {
		const deepTestResults = sections.map((section) => ({
			section: section.title,
			responses: section.questions.map((question) => ({
				question: question.label,
				answer:
					answers[question.name] !== ''
						? answers[question.name]
						: null,
			})),
		}));
		console.log('Submitting answers:', deepTestResults);
		mutation.mutate(deepTestResults);
	};

	const handleSaveAndClose = () => {
		handleSubmit();
		handleCloseDialog();
		onClose();
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleCloseWithoutSaving = () => {
		setOpenDialog(false);
		onClose();
	};

	const handleCloseWithWarning = () => {
		if (unsavedChanges) {
			setOpenDialog(true);
		} else {
			onClose();
		}
	};

	return (
		<>
			<Modal open={open} onClose={handleCloseWithWarning}>
				<Paper
					sx={{
						padding: { xs: 2, sm: 3 },
						margin: { xs: 1, sm: 2 },
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						position: 'absolute',
						width: { xs: '90%', sm: '80%', md: '60%' },
						maxHeight: '90vh',
						overflowY: 'auto',
						borderRadius: { xs: 2, sm: 2 },
						boxShadow: (theme) => theme.shadows[5],
					}}
				>
					{loadingInitialData ? (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%',
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<>
							<Typography variant="h6" gutterBottom>
								Syventävä kysely
							</Typography>
							<Tabs
								value={currentTab}
								onChange={handleTabChange}
								variant="scrollable"
								scrollButtons="auto"
								sx={{ mb: 2 }}
							>
								{sections.map((section, index) => (
									<Tab label={section.title} key={index} />
								))}
							</Tabs>
							{sections.map((section, sectionIndex) => (
								<Box
									key={sectionIndex}
									hidden={currentTab !== sectionIndex}
									sx={{ marginTop: 4 }}
								>
									<Typography
										variant="h6"
										sx={{ marginBottom: 2 }}
									>
										{section.title}
									</Typography>
									{section.description && (
										<Typography
											variant="body2"
											sx={{ marginBottom: 2 }}
										>
											{section.description}
										</Typography>
									)}
									{section.questions.map((question) => (
										<Box
											key={question.name}
											sx={{ marginBottom: 2 }}
										>
											<Typography
												variant="body1"
												gutterBottom
											>
												{question.label}
											</Typography>
											{question.type === 'text' && (
												<TextField
													name={question.name}
													value={
														answers[
															question.name
														] || ''
													}
													onChange={handleChange}
													multiline
													rows={4}
													fullWidth
												/>
											)}
											{question.type === 'number' && (
												<Slider
													name={question.name}
													value={
														answers[
															question.name
														] || question.min
													}
													onChange={handleSliderChange(
														question.name,
													)}
													min={question.min}
													max={question.max}
													step={question.step}
													valueLabelDisplay="auto"
												/>
											)}
											{question.type === 'yesno' && (
												<FormControlLabel
													control={
														<Switch
															checked={
																answers[
																	question
																		.name
																] || false
															}
															onChange={
																handleChange
															}
															name={question.name}
															color="primary"
														/>
													}
													label={question.label}
												/>
											)}
											{question.type === 'opinion' && (
												<Box>
													<Grid
														container
														alignItems="center"
														spacing={2}
													>
														<Grid item xs={12}>
															<Slider
																name={
																	question.name
																}
																value={
																	answers[
																		question
																			.name
																	] ||
																	question.min
																}
																onChange={handleSliderChange(
																	question.name,
																)}
																min={
																	question.min
																}
																max={
																	question.max
																}
																step={
																	question.step
																}
																marks
																valueLabelDisplay="auto"
																sx={{
																	width: '100%',
																}}
															/>
														</Grid>
														<Grid item xs={6}>
															<Typography
																variant="body2"
																align="left"
															>
																{
																	question.minLabel
																}
															</Typography>
														</Grid>
														<Grid item xs={6}>
															<Typography
																variant="body2"
																align="right"
															>
																{
																	question.maxLabel
																}
															</Typography>
														</Grid>
													</Grid>
												</Box>
											)}
										</Box>
									))}
								</Box>
							))}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Button
									variant="contained"
									color="primary"
									disabled={currentTab === 0}
									onClick={() =>
										setCurrentTab((prev) => prev - 1)
									}
								>
									Edellinen
								</Button>
								{currentTab === sections.length - 1 ? (
									<Button
										variant="contained"
										color="secondary"
										onClick={handleSaveAndClose}
										disabled={mutation.isLoading}
									>
										Valmis
									</Button>
								) : (
									<Button
										variant="contained"
										color="secondary"
										onClick={() =>
											setCurrentTab((prev) => prev + 1)
										}
									>
										Seuraava
									</Button>
								)}
							</Box>
							{mutation.isError && (
								<Typography color="error" sx={{ marginTop: 2 }}>
									Jotain meni pieleen. Yritä uudelleen.
								</Typography>
							)}
						</>
					)}
				</Paper>
			</Modal>

			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Tallentamattomia muutoksia
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Sinulla on tallentamattomia muutoksia. Haluatko
						tallentaa muutokset ennen poistumista?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog} color="primary">
						Peruuta
					</Button>
					<Button
						onClick={handleCloseWithoutSaving}
						variant="outlined"
						color="primary"
					>
						Poistu tallentamatta
					</Button>
					<Button
						onClick={handleSaveAndClose}
						variant="contained"
						color="secondary"
						autoFocus
					>
						Tallenna ja poistu
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Questionnaire;
