import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authService } from 'services';

export const useLogout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const logout = async () => {
		try {
			await authService.logout(dispatch);
			navigate('/');
		} catch (error) {
			console.error('Logout failed:', error);
		}
	};

	return logout;
};
