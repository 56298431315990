import React, { useState } from 'react';
import {
	TextField,
	Button,
	MenuItem,
	Slider,
	Box,
	Typography,
	Paper,
	Grid,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import backgroundImage from '../../../logo-lenz.png'; // Lataa ja aseta kuva tähän
import { talentService } from '../../../services';
import { Link } from 'react-router-dom';

const TalentSearch = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [education, setEducation] = useState('');
	const [category, setCategory] = useState('');
	const [experience, setExperience] = useState([0, 10]);

	const [results, setResults] = useState([]);
	const [showAdvanced, setShowAdvanced] = useState(false);

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleJobTitleChange = (event) => {
		setEducation(event.target.value);
	};

	const handleCategoryChange = (event) => {
		setCategory(event.target.value);
	};

	const handleExperienceChange = (event, newValue) => {
		setExperience(newValue);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const filters = {};
		if (education) filters.education = education;
		if (category) filters.category = category;
		const response = await talentService.hybridSearch(searchTerm, filters);
		console.log(response);
		setResults(response);
	};

	const renderCard = (person, index) => (
		<Grid item xs={12} sm={6} md={4} key={index}>
			<Card sx={{ height: '100%' }}>
				<CardHeader title={person.name} />
				<CardContent>
					<Typography variant="body2" color="textSecondary">
						Koulutus: {person.education}
					</Typography>
					<Typography variant="body2" color="textSecondary">
						Toimiala: {person.category}
					</Typography>
					<Button
						component={Link}
						to={`/talents/${person._id}`}
						fullWidth
						variant="outlined"
					>
						Tutustu Talenttiin
					</Button>
				</CardContent>
			</Card>
		</Grid>
	);

	return (
		<Box
			sx={{
				mx: 'auto',
				p: 3,
				backgroundImage: `url(${backgroundImage})`,
				backgroundPosition: 'center',
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
				borderRadius: 2,
				boxShadow: 3,
			}}
		>
			<Paper
				elevation={3}
				sx={{
					p: 4,
					mb: 4,
					backgroundColor: 'rgba(255, 255, 255, 0.8)',
				}}
			>
				<form onSubmit={handleSubmit}>
					<TextField
						fullWidth
						label="Etsi talentteja"
						multiline
						rows={4}
						variant="outlined"
						value={searchTerm}
						onChange={handleSearchChange}
						sx={{ mb: 3 }}
					/>
					<Box sx={{ textAlign: 'right', mb: 2 }}>
						<IconButton
							onClick={() => setShowAdvanced(!showAdvanced)}
						>
							{showAdvanced ? (
								<ExpandLessIcon />
							) : (
								<ExpandMoreIcon />
							)}
						</IconButton>
					</Box>
					<Collapse in={showAdvanced}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									fullWidth
									label="Koulutus"
									variant="outlined"
									value={education}
									onChange={handleJobTitleChange}
									sx={{ mb: { xs: 3, md: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									select
									fullWidth
									label="Toimiala"
									variant="outlined"
									value={category}
									onChange={handleCategoryChange}
									sx={{ mb: { xs: 3, md: 0 } }}
								>
									<MenuItem value="">
										<em>Valitse toimiala</em>
									</MenuItem>
									<MenuItem value="Tech">Tech</MenuItem>
									<MenuItem value="Business">
										Business
									</MenuItem>
									<MenuItem value="Design">Design</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={12}>
								<Typography gutterBottom>
									Kokemus (vuosina):
								</Typography>
								<Slider
									value={experience}
									onChange={handleExperienceChange}
									valueLabelDisplay="auto"
									min={0}
									max={30}
									marks
									sx={{ mb: 3 }}
								/>
							</Grid>
						</Grid>
					</Collapse>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
					>
						Hae
					</Button>
				</form>
			</Paper>
			<Box sx={{ mt: 3 }}>
				<Typography variant="h6" gutterBottom>
					Ehdotukset:
				</Typography>
				<Grid container spacing={3}>
					{results?.length > 0 ? (
						results.map(renderCard)
					) : (
						<Typography variant="body1">Ei tuloksia</Typography>
					)}
				</Grid>
			</Box>
		</Box>
	);
};

export default TalentSearch;
