import { useState, useEffect } from 'react';

export const useProfileFormVisibility = (isTalent, talent) => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (
			isTalent &&
			(!talent?.city ||
				!talent?.university ||
				!talent?.stage ||
				!talent?.major ||
				!talent?.phone)
		) {
			setIsOpen(true);
		}
	}, [isTalent, talent]);

	return [isOpen, setIsOpen];
};
